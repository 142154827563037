app.factory('Courses_AdminOverride', ['$injector', '$location' , '$q', '$filter', '$rootScope', '$route', '$timeout', '$window', '$uibModal', 'CatalogService', 'CoursesService', 'DocumentService', 'LocationsService', 'OfferingsService', 'RosterService', 'SettingsService', 'UsersService', 'dateUtil', 'messagingDisplayerUtil', 'sessionFormatterUtil', 'WizardHandler', 'TreeHelpers', 'sortService', 'CategoriesService', 'SkillService',
    function ($injector, $location , $q, $filter, $rootScope, $route, $timeout, $window, $uibModal, CatalogService, CoursesService, DocumentService, LocationsService, OfferingsService, RosterService, SettingsService, UsersService, dateUtil, messagingDisplayerUtil, sessionFormatterUtil, WizardHandler, TreeHelpers, sortService, CategoriesService, SkillService) {
        return function ($scope) {
            var _this = this;

            $scope.requiredOptions = [{
                "name": "Yes",
                "value": true
            }, {
                "name": "No",
                "value": false
            }]; 

            $scope.defaultHeader = true; // This Sets the page to full width of the screen.
            $scope.hideCatalogSearch = true;

            /* This will make the navigation tab active on certain page */
            $scope.activeTab = 'tab-active-class';

            /*This will route users to the appropiate user manual based on active tab*/
            $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Content';

            //Need an obj reference to pass scope to ng-include attr in html.
            $scope.tab2_Options = {};
            $scope.contentSearch = "assignmentsSearch.name";
            /* Training Insights access determined by the organization settings */
            var sett = SettingsService.getSettings().then(function (success) {
                $scope.IsRusticiFlag = success.features.sysPropRusticiAccess;
                $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
                $scope.showCareerPath = SettingsService.isCareerPathEnabled();
                $scope.isDocumentsEnabled = SettingsService.isDocumentsEnabled();
                _this.shouldShowDocuments();
            });

            $scope.rows = Array();

            _this.originalRowData = Array(); // stores the original data from the response

            $scope.tab2_Options.baiCatalogTreeFilter = "";
            $scope.baiCatalogIsLoading = true;
            $scope.rowsAreLoading = true;

            _this.currentCourseSet = [];
            $scope.customCatalogData = [];

            _this.params = $route.current.params;
            /* This makes coloumns of a table sortables */
            $scope.propertyName = 'name';
            $scope.reverse = false;

            $scope.sortBy = function (propertyName) {
                $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                $scope.propertyName = propertyName;

                _this.setStartandEndIndex();
            };

            $scope.general = {};
            $scope.general.search = null;
            $scope.general.typeaheadMinLength = 3;
            $scope.general.typeaheadWaitTime = 800;

            $scope.sequenceCourseSave = false;
            $scope.courseactivetab = false;
            $scope.showcoursesactivetab = true;
            $scope.general.filterBySearch = function () {
                var searchTerm = $scope.general.search;
                var rows = angular.copy(_this.allCoursesData);

                var searchProp = $scope.property;
                var filtered = {};
                var filterExpression = {};
                filterExpression[searchProp] = searchTerm;
                switch (searchProp) {
                    case 'displayStart':
                        filtered = $filter('filter')(rows, { displayStart: { fullDate: searchTerm } });
                        break;
                    case 'displayEnd':
                        filtered = $filter('filter')(rows, { displayEnd: { fullDate: searchTerm } });
                        break;
                    default:
                        //filtered = $filter('filter')(rows, filterExpression);
                        filtered = $filter('filter')(rows, function (rows) {
                            if (searchProp === "name")
                                return (rows.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1;
                            else if (searchProp === "code")
                                return (rows.code.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1;
                            else if (searchProp === "type")
                                return (rows.type.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1;
                            else
                                return ((rows.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                    (rows.code.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                    (rows.type.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1);
                        });
                        break;
                }

                _this.calculatePages(filtered);
                _this.setStartandEndIndex();
                $scope.rows = filtered.slice($scope.startIndex, $scope.endIndex);
            };

            /**
             * Generic controller level controls
             * @type {{}}
             */
            $scope.courses = {};
            $scope.courses.isILTEnabled = false;
            $scope.courses.isDocumentsEnabled = false;

            /**
             * Subscriber to ensure that we set views properly
             *
             * Note: this is not a great pattern but it's the only way to truly do away with race conditions, since
             * either the service may not be done or the template my not be loaded
             */
            $scope.$watch(function () {
                return $rootScope.sysSettings;
            }, function () {
                $scope.courses.isILTEnabled = SettingsService.isILTEnabled();
                $scope.courses.isDocumentsEnabled = window.customDocumentsEnabled;
            });

            $scope.property = "$";
            $scope.changeFilter = function (property) {
                if (property === "all") {
                    $scope.property = "$";
                } else {
                    $scope.property = property;
                }
            };

            $scope.baiCatalogError = {};
            $scope.baiCatalogOptions = {};
            $scope.baiCatalogError.error = messagingDisplayerUtil.create();
            $scope.baiCatalogTreeTitle = "BAI Catalog";
            $scope.baiCatalogOptions.initiallyOpened = true;

            $scope.assignmentTableError = {};
            $scope.assignmentTableError.error = messagingDisplayerUtil.create();

            $scope.home = {};

            $scope.homeAction = function () {
                $scope.home.page = 1;
                $scope.home.limit = 100;
                $scope.home.total = 0;
                $scope.home.maxVisiblePages = 10;

                $scope.homeTemplate = 'app/templates/courses/home/admin.html';
                /* this extremely small timeout is for IE - makes the courses home page load with loading bars and then call the api
                    otherwise it appears as if the courses tab was not selected
                */
                setTimeout(function () {
                    _this.allCourses();
                }, 2);
            };

            $scope.home.nextPage = function () {
                var start = ($scope.home.page * $scope.home.limit) - $scope.home.limit;

                var copy = angular.copy(_this.currentCourseSet);

                $scope.rows = copy.splice(start, $scope.home.limit);
            };

            $scope.manageCourse = {};
            $scope.manageCourse.error = messagingDisplayerUtil.create();
            $scope.manageCourse.isLoading = false;
            $scope.manageCourse.success = messagingDisplayerUtil.create();

            $scope.manageCourse.onSessionsTab = false;
            $scope.manageCourse.onOfferingsTab = false;
            $scope.manageCourse.onRosterTab = false;

            $scope.manageCourse.showDocumentURLInput = false;
            $scope.manageCourse.showDocumentFileInput = false;

            $scope.editCourse = {};
            $scope.editCourse.error = messagingDisplayerUtil.create();
            $scope.editCourse.isLoading = false;
            $scope.availableSkills = null;
            $scope.prereqsToInsert = []; /* holds an array of all courseIDs for prereqs that were added or removed in a session */
            $scope.prereqsToRemove = [];
            $scope.editAction = function () {
                $scope.homeTemplate = 'app/templates/courses/edit/admin.html';
                $scope.manageCourse.isLoading = true;
                $scope.manageCourse.course.iModifyLoading = false;
                $scope.manageCourse.course.previewStatus = {};

                var params = $route.current.params.param.split('/');
                var courseId = params[0];

                var coursePromises = [CoursesService.getSingleCourse(courseId), SkillService.fetch(), CategoriesService.fetch(null, false, true)];
                $q.all(coursePromises).then(function (success) {
                    $scope.manageCourse.course = success[0];
                    $scope.manageCourse.childrenUpdated = false;

                    if ($scope.manageCourse.course.normalizedProductType === "document") {
                        var documentCourseId = $scope.manageCourse.course.baiOnly ? $scope.manageCourse.course.parentBAICourseId : courseId;
                        _this.getDocumentName(documentCourseId, $scope.manageCourse.course.type);
                        $scope.DescriptionSelectModalTitle = "Enter a document description";
                    }
                    if ($scope.manageCourse.course.normalizedProductType === "course" || $scope.manageCourse.course.type === 'ilt' || $scope.manageCourse.course.type === 'wbt' || $scope.manageCourse.course.type === 'assessment' ||$scope.manageCourse.course.type === 'courseGroup' || $scope.manageCourse.course.type === 'courseGroupCertification' || $scope.manageCourse.course.type === 'task' || $scope.manageCourse.course.type === 'evaluation'||$scope.manageCourse.course.type === 'survey' || $scope.manageCourse.course.type === 'courseGroupCurricula' || $scope.manageCourse.course.type === 'careerPath' ) {
                        $scope.DescriptionSelectModalTitle = "Enter a course description";
                    }

                    if ($scope.manageCourse.course.type === "wbt" || ($scope.manageCourse.course.normalizedProductType === "document" && !DocumentService.isDirectLaunchType($scope.manageCourse.course.type))) {
                        var rusticiCourseId = $scope.manageCourse.course.parentBAICourseId !== null ? $scope.manageCourse.course.parentBAICourseId : courseId;
                        _this.getPreviewStatus(rusticiCourseId, $scope.manageCourse.course.baiOnly);
                    }

                    $scope.availableSkills = success[1];
                    if ($scope.manageCourse.course.type !== 'ilt') {
                        // All other cours types only have course details, only ILTs have more
                        $scope.manageCourse.isLoading = false;
                    }
                    $scope.manageCourse.cancelMessage = "Are you sure want to cancel?";
                    _this.getSecondaryDataForEdit();
                    _this.setEvaluations();

                    if ($scope.manageCourse.course.type === 'ilt' || $scope.manageCourse.course.type === 'wbt') {
                        _this.getCoursePrerequisits();
                    }
                    if ($scope.manageCourse.course.type === 'wbt') {
                        // even if we do not find record in manifest table it should be default selected to SCORM
                        if ($scope.manageCourse.course.shellType === null) {
                            $scope.manageCourse.course.shellType = 1;
                        }
                        _this.getContentTypes();
                    }

                    $scope.showcoursesactivetab = false;

                    //if the course is a custom course - configure the category selects
                    if (!$scope.manageCourse.course.baiOnly) {
                        var filterby = $scope.manageCourse.course.productType === 'Document' ? 'document' : 'course';
                        if (filterby !== 'document') {
                            success[2] = CategoriesService.RemoveDocumentPolicies(success[2]);
                        }
                        $scope.taxonomyOptions = $filter('filter')(success[2], { categoryType: filterby });
                        $scope.subcategoryDisabled = true;
                        $scope.createUniqueCategoryList();

                        //pre-fill selects if a catalog ID is already selected
                        if ($scope.manageCourse.course.catalogId !== null) {

                            $scope.selectCategory();

                        }
                    }

                }, function (failure) {
                    $scope.manageCourse.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.manageCourse.error.setError(errorCode, message);
                });
            };

            _this.getSecondaryDataForEdit = function () {
                _this.setSecondaryProperties($scope.manageCourse.course.type);
                switch ($scope.manageCourse.course.type) {
                    case 'ilt':
                        $scope.manageCourse.getCourseOfferings();
                        break;
                }
            };

            $scope.coursePrerequisitsOnLoad = [];
            _this.getCoursePrerequisits = function () {
                var prereqCall = CoursesService.getCoursePrereqs($scope.manageCourse.course.id);
                prereqCall.then(function (success) {
                    $scope.manageCourse.course.prereq = success;
                    $scope.coursePrerequisitsOnLoad = angular.copy(success);
                });
            };

            _this.getContentTypes = function () {
                var contentTypes = CoursesService.getContentTypes();
                contentTypes.then(function (success) {
                    $scope.courseContentTypes = success;
                });
            };

            $scope.launchUrl = function (url) {
                //SLY-3933
                _this.updatePassingScorelaunchUrl(url);

            };

            _this.getPreviewStatus = function (courseID, baiOnly) {
                var previewStatus = CoursesService.getPreviewStatus(courseID);
                previewStatus.then(function (success) {
                    var status = {};
                    status.message = null;
                    status.viewMore = false;
                    status.ready = false;
                    status.processing = false;

                    if (success !== null) {
                        if (success.statusID === 1 || success.statusID === 4) {
                            if ($scope.manageCourse.course.type === "wbt") {
                                status.message = 'Notice - Your course is still being processed, please wait and check back later.';
                            } else {
                                status.message = 'Notice - Your document is still being processed, please wait and check back later.';
                            }
                            status.warning = true;
                            status.processing = true;
                            
                        } else if (success.statusID === 2) {
                            status.ready = true;
                        } else if (success.statusID === 3) {
                            if (!baiOnly) {
                                status.message = 'Error - The manifest failed to load to the system.';
                                status.error = true;
                                var errorDate = new Date(success.processed).toString();
                                status.errorMessage = errorDate + ' ' + success.errorMessage;
                            }
                        }
                    } else {
                        //no record exists in rustici queue so has not been created
                        status.message = 'No content loaded.';
                        status.warning = true;
                    }

                    $scope.manageCourse.course.previewStatus = status;
                    
                });
            };

            _this.getDocumentName = function (courseID, docType) {
                var result = DocumentService.getDocumentPath(courseID);
                result.then(function (success) {
                    if (success !== null) {
                        if (docType.toLowerCase() === "url") {
                            $scope.manageCourse.course.documentName = success;
                        }
                        else {
                            $scope.manageCourse.course.documentName = success.split('\\').pop().split('/').pop();
                        }

                        $scope.manageCourse.course.showDocumentName = true;
                        _this.setDocumentPreviewStatus(true);
                    }
                    else {
                        _this.setDocumentPreviewStatus(false);
                    }
                });
            };

            _this.setDocumentPreviewStatus = function (ready) {
                var status = {};
                status.message = null;
                status.viewMore = false;
                status.ready = ready;
                status.processing = false;

                if (!ready) {
                    status.message = 'No content loaded.';
                    status.warning = true;
                }

                $scope.manageCourse.course.previewStatus = status;
            };

            var hasOfferingErrors = false;

            $scope.sendEditCourseCall = function (deactivate, confirmed) {

                if (($scope.manageCourse.childrenUpdated === true || $scope.manageCourse.skillsUpdated === true) && confirmed !== true) {
                    $scope.manageCourse.confirmIsOpen = true;
                    return;
                }
                else { $scope.manageCourse.confirmIsOpen = false; }

                $scope.editCourse.isLoading = true;

                if (typeof deactivate !== 'undefined' && deactivate === true) {
                    $scope.manageCourse.course.displayEnd = dateUtil.formatDate(new Date());
                    $scope.manageCourse.course.isActive = false;
                } else if (typeof deactivate !== 'undefined' && deactivate === false) {
                    $scope.manageCourse.course.displayEnd = null;
                    $scope.manageCourse.course.isActive = true;
                }

                var allPromises = [];

                if ($scope.manageCourse.course.normalizedProductType === "document" &&
                    ((typeof $scope.manageCourse.baiform.documentUpload !== 'undefined' && $scope.manageCourse.baiform.documentUpload.$dirty) ||
                        (typeof $scope.manageCourse.baiform.urlUpload !== 'undefined' && $scope.manageCourse.baiform.urlUpload.$dirty))) {


                    //set the new document type before the common course update
                    var docUpload = {};
                    if ($scope.manageCourse.documentType === 'url' && $scope.manageCourse.urlUpload !== null) {
                        $scope.manageCourse.course.type = 'url';
                        $scope.manageCourse.course.documentName = $scope.manageCourse.urlUpload;
                        $scope.manageCourse.course.showDocumentName = true;
                        docUpload = DocumentService.uploadDocumentUrl($scope.manageCourse.urlUpload, $scope.manageCourse.course.id);
                        allPromises.push(docUpload);
                    } else {
                        if ($scope.manageCourse.documentUpload !== null) {
                            var filename = $scope.manageCourse.documentUpload.name;
                            $scope.manageCourse.course.documentName = $scope.manageCourse.documentUpload.name;
                            $scope.manageCourse.course.showDocumentName = true;
                            var ext = filename.substring(filename.lastIndexOf('.'), filename.length);
                            $scope.manageCourse.course.type = DocumentService.getDocumentTypeByExt(ext);
                            docUpload = DocumentService.uploadDocumentToStorage($scope.manageCourse.documentUpload, $scope.manageCourse.course.id);
                            allPromises.push(docUpload);
                        }
                    }
                }

                var course = angular.copy($scope.manageCourse.course);
                if (course.type === 'careerPath') {
                    course.childCourses = _this.getOrderedChildCourses(course.childCourses);
                }

                //set the resourceID to launch with content based on the selected resource ID in document uploader
                course.resourceLaunchWithContent = $scope.manageCourse.course.resourceLaunchWithContent;

                if (typeof course.type !== 'undefined' && course.type === "ilt") {
                    var courseIltUpdate = _this.updateCourseIlt(course);
                    if (typeof courseIltUpdate !== 'undefined' && courseIltUpdate !== null) {
                        allPromises.push(courseIltUpdate);
                    }
                }

                var courseCommonUpdate = _this.updateCourseCommonProp(course);
                if (typeof courseCommonUpdate !== 'undefined' && courseCommonUpdate !== null) {
                    allPromises.push(courseCommonUpdate);
                }

                var courseAssetsUpdate = _this.updateCourseAssets(course);
                if (typeof courseAssetsUpdate !== 'undefined' && courseAssetsUpdate !== null && !$scope.sequenceCourseSave) {
                    //If we do not need to sequence the save, then add resource api calls to allPromises and proceed with async api calls
                    allPromises.push(courseAssetsUpdate);
                }

                //for WBT or ILT, if there are any prereqs to remove or to insert, call the prereq update 
                if (typeof course.type !== 'undefined' && (course.type === "ilt" || course.type === "wbt") && ($scope.prereqsToRemove.length > 0 || $scope.prereqsToInsert.length > 0)) {
                    var prereqsIdsToUpdate = course.id + (($scope.prereqsToRemove.length > 0) ? '|' : '') + $scope.prereqsToRemove.join('|') + '|' + $scope.prereqsToInsert.join('|');
                    var newPrereqList = [];
                    $scope.manageCourse.course.prereq.forEach(function (p) { newPrereqList.push(p.id); });
                    var prereqCall = CoursesService.updateCoursePrerequisits(course.id, prereqsIdsToUpdate, newPrereqList);
                    allPromises.push(prereqCall);
                }

                $q.all(allPromises).then(function (success) {
                    $scope.editCourse.isLoading = false;
                    $scope.manageCourse.childrenUpdated = false;
                    $scope.manageCourse.skillsUpdated = false;

                    if ($scope.sequenceCourseSave) {
                        //If sequenceCourseSave is true, that means there is a new doc/url that is set to launch with the course
                        //this api needs to be called AFTER the course save API because we need to save the new resourceID to the course
                        //without it being overridden by the course update API call
                        $q.all(courseAssetsUpdate).then(function (success2) {
                            $scope.manageCourse.success.setError(200, 'Successfully saved Course changes', true);
                        });
                    } else if (course.type === "ilt") {
                        if (hasOfferingErrors === false)
                            $scope.manageCourse.success.setError(200, 'Successfully saved Course changes', true);
                    } else {
                        $scope.manageCourse.success.setError(200, 'Successfully saved Course changes', true);
                    }
                    //Clear out the prereq arrays in case the user stays on the page and edits the prereqs again
                    $scope.prereqsToInsert = [];
                    $scope.prereqsToRemove = [];
                    $scope.coursePrerequisitsOnLoad = angular.copy($scope.manageCourse.course.prereq);
                    //get the latest preview status if WBT
                    if ($scope.manageCourse.course.type === "wbt" || $scope.manageCourse.course.normalizedProductType === "document" && !DocumentService.isDirectLaunchType($scope.manageCourse.course.type)) {
                        var rusticiCourseId = $scope.manageCourse.course.parentBAICourseId !== null ? $scope.manageCourse.course.parentBAICourseId : $scope.manageCourse.course.id;
                        _this.getPreviewStatus(rusticiCourseId, $scope.manageCourse.course.baiOnly);
                    }

                    /* after course edit - reload the courses for the TA Courses home */
                    CoursesService.getActiveCoursesForOrg(true, true);

                }, function (failure) {
                    $rootScope.$broadcast("RefreshDocList", { courseId: course.id });
                    $scope.editCourse.isLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.manageCourse.error.setError(errorCode, message);
                });

            };

            $scope.toggleConfirmation = function () {
                jQuery('.confirmiltSave').toggle();
            };

            _this.updateCourseIlt = function (course) {
                hasOfferingErrors = false;
                $scope.manageCourse.editCourseOfferingsError.reset();
                $scope.manageCourse.editCourseOfferingsRosterError.reset();

                var batchUpdateOfferings = [];
                var batchAddOfferings = [];
                _.each($scope.manageCourse.course.offerings, function (offering) {
                    //only update the offerings that have been touched.  SLY-6551
                    if (offering.isDirty) {

                        offering.assignmentType = 'ilt';

                        if (offering.requestedOn !== undefined) {
                            offering.requestedOn = dateUtil.formatDate(new Date(offering.requestedOn.fullDateWithTime));
                        }
                        if (offering.registrationEndDateTime === undefined || offering.registrationEndDateTime === null) {
                            offering.registrationEndDateTime = dateUtil.formatDate(offering.maxRegistrationEndDateTime);
                        }

                        offering.registrationEndDateTime = dateUtil.formatDate(new Date(offering.registrationEndDateTime.fullDateWithTime));

                        var offeringCopy = angular.copy(offering);
                        var sessions = offeringCopy.sessions;
                        if (typeof sessions !== 'undefined' && sessions !== null && sessions.length > 0) {
                            for (var i = 0; i < sessions.length; i++) {

                                var endDate = moment(sessions[i].endDate);
                                var endTime = moment(sessions[i].endTime);
                                var startDate = moment(sessions[i].startDate);
                                var startTime = moment(sessions[i].startTime);

                                var endDateClone = moment(endDate);
                                endDateClone.set({ hour: endTime.hours(), minute: endTime.minutes(), second: 0, millisecond: 0 });
                                var startDateClone = moment(startDate);
                                startDateClone.set({ hour: startTime.hours(), minute: startTime.minutes(), second: 0, millisecond: 0 });

                                sessions[i].endDateTime = dateUtil.formatDate(endDateClone.toDate());
                                sessions[i].startDateTime = dateUtil.formatDate(startDateClone.toDate());
                            }
                        }

                        if (typeof offering.id !== 'undefined') {
                            batchUpdateOfferings.push(offeringCopy);
                        } else {
                            batchAddOfferings.push(offeringCopy);
                        }
                    }
                });

                var offeringsUpsertPromises = [];
                var errormessage = "";

                if (batchUpdateOfferings.length > 0) {

                    var updatepromisedefer = $q.defer();

                    var batchupdatepromise = OfferingsService.updateOfferingsBatch(batchUpdateOfferings);

                    batchupdatepromise.then(function (success) {

                        _.each(success, function (updateditem) {

                            var offering = _.findWhere($scope.manageCourse.course.offerings, { id: updateditem.offering.id });

                            if (updateditem.status === "Success") {
                                offering.isDirty = false;

                                if (offering.roster.isDirty) {
                                    _this.updateOfferingRoster(offering);
                                }
                                else {
                                    offering.roster = updateditem.offering.roster;
                                    _this.setOfferingAccordionHeading(offering);
                                }

                                // Update UI sessions with ids from sessions in API response
                                _.each(offering.sessions, function (session) {
                                    // Find each api session where start date matches UI session start date and end date matches UI session end date
                                    var apiSession = _.find(updateditem.offering.sessions, function (s) {
                                        var sd = new Date(s.startDateTime);
                                        var ed = new Date(s.endDateTime);
                                        return session.startDate.toLocaleString() === sd.toLocaleString() && session.endDate.toLocaleString() === ed.toLocaleString();
                                    });
                                    // Set UI session id = api session id. This is necessary so API knows which sessions are new or existing
                                    session.id = apiSession.id;
                                });
                            } else {
                                errormessage = errormessage === "" ? updateditem.status : errormessage + "\r\n" + updateditem.status;
                            }
                        });

                        updatepromisedefer.resolve();

                    }, function (failure) {
                        errormessage = errormessage === "" ? failure.ErrorMessage : errormessage + "\r\n" + failure.ErrorMessage;
                        updatepromisedefer.resolve();
                    });

                    offeringsUpsertPromises.push(updatepromisedefer.promise);
                }

                if (batchAddOfferings.length > 0) {
                    var addpromisedefer = $q.defer();
                    var batchAddPromise = OfferingsService.createOfferings(batchAddOfferings);

                    batchAddPromise.then(function (success) {

                        _.each(success, function (createdItem) {

                            var offering = _.findWhere($scope.manageCourse.course.offerings, { localId: createdItem.offering.localId, name: createdItem.offering.name });

                            if (createdItem.status === "Success") {
                                offering.isDirty = false;

                                offering.id = createdItem.offering.id;
                                offering.code = createdItem.offering.code;
                                if (offering.roster.isDirty) {
                                    _this.updateOfferingRoster(offering);
                                }
                            } else {
                                errormessage = errormessage === "" ? createdItem.status : errormessage + "\r\n" + createdItem.status;
                            }
                        });

                        addpromisedefer.resolve();

                    }, function (failure) {
                        errormessage = errormessage === "" ? failure.ErrorMessage : errormessage + "\r\n" + failure.ErrorMessage;
                        addpromisedefer.resolve();
                    });

                    offeringsUpsertPromises.push(addpromisedefer.promise);
                }

                var deferredOfferingsPromise = $q.defer();
                $q.all(offeringsUpsertPromises).then(function (data) {
                    if (errormessage !== "") {
                        hasOfferingErrors = true;
                        $scope.manageCourse.editCourseOfferingsError.setError("", errormessage);
                    }

                    deferredOfferingsPromise.resolve("success");
                });

                return deferredOfferingsPromise.promise;
            };

            _this.updateOfferingRoster = function (offering) {
                var roster = offering.roster.attendingStudents;
                if (roster !== 'undefined') {
                    var registerToRoster = [];
                    _.each(roster, function (user) {
                        user.error = messagingDisplayerUtil.create();
                        if (typeof user.isExisting === 'undefined' || user.isExisting === null) {
                            var rosterUser = {};
                            rosterUser.StudentId = user.id;
                            rosterUser.OfferingId = offering.id;
                            rosterUser.RegistrationStatus = 'confirmed';
                            rosterUser.AssignmentType = 'ilt';

                            registerToRoster.push(rosterUser);
                        }
                    });

                    if (registerToRoster.length > 0)
                        _this.batchUpdateUserInRoster(registerToRoster, offering);
                }
            };


            $scope.updateCourseOfferingRoster = function (course) {
                $scope.manageCourse.stepSix = {};

                var registerToRoster = [];

                var offerings = course.offerings;
                _.each(offerings, function (offering) {
                    var roster = offering.roster.attendingStudents;
                    registerToRoster = [];
                    _.each(roster, function (user) {
                        user.error = messagingDisplayerUtil.create();
                        if (typeof offering.id === 'undefined') {
                            user.error.setError("", "Offering does not exist.");
                            return;
                        }

                        if (typeof user.isExisting === 'undefined' || user.isExisting === null) {
                            var rosterUser = {};
                            rosterUser.StudentId = user.id;
                            rosterUser.OfferingId = offering.id;
                            rosterUser.RegistrationStatus = 'confirmed';
                            rosterUser.AssignmentType = 'ilt';

                            registerToRoster.push(rosterUser);
                        }
                    });

                    if (registerToRoster.length > 0)
                        _this.batchUpdateUserInRoster(registerToRoster, offering);

                });
            };

            _this.updateCourseCommonProp = function (course) {
                var coursePromise = CoursesService.updateCourse(course);
                return coursePromise;
            };

            _this.updateCourseAssets = function (course) {
                var courseCustomPromise = _this._attachFiles(course.id, $scope.manageCourse.scormPackages, $scope.manageCourse.course.documentList, $scope.manageCourse.course.shellType);
                return courseCustomPromise;
            };

            $scope.manageCourse.editCourseOfferingsRosterError = messagingDisplayerUtil.create();
            $scope.manageCourse.editCourseOfferingsError = messagingDisplayerUtil.create();
            $scope.manageCourse.editOfferingsIsLoading = true;
            $scope.manageCourse.showOfferingsLoading = true;
            $scope.manageCourse.stepFive = {};
            $scope.manageCourse.stepFive.error = messagingDisplayerUtil.create();

            $scope.manageCourse.showOfferingsWithStatus = "open";
            $scope.displayedOfferings = [];


            $scope.manageCourse.getCourseOfferings = function () {
                var courseId = $route.current.params.param.split('/')[0];
                $scope.manageCourse.editCourseOfferingsError.reset();
                $scope.manageCourse.editOfferingsIsLoading = true;
                $scope.manageCourse.showOfferingsLoading = true;
                $scope.manageCourse.initializeSessionCreator();

                if (typeof $scope.manageCourse.course.offerings !== 'undefined' && $scope.manageCourse.course.offerings !== null) {
                    $scope.manageCourse.editOfferingsIsLoading = false;
                    $scope.manageCourse.showOfferingsLoading = false;
                    $scope.manageCourse.isLoading = false;
                    return true;
                }

                var courseOfferingsPromise = CoursesService.getSingleCourseOfferings(courseId);

                var locationsPromise = LocationsService.getAllActive();
                var instructorsPromise = UsersService.getAllInstructors();

                var offeringsMetaDataPromises = [];
                offeringsMetaDataPromises.push(courseOfferingsPromise);
                offeringsMetaDataPromises.push(locationsPromise);
                offeringsMetaDataPromises.push(instructorsPromise);

                locationsPromise.then(function (success) {
                    $scope.manageCourse.locations = success;
                }, function (failure) {
                });

                instructorsPromise.then(function (success) {
                    $scope.manageCourse.stepFour.instructors = success;
                    // We want to run course offerings after instructor finishes
                    return courseOfferingsPromise;
                }, function (failure) {
                    // We want to run course offerings after instructor finishes even if it fails.
                    return courseOfferingsPromise;
                }).then(function (success) { // Chained course offerings promise

                    $scope.manageCourse.course.offerings = success; // offerings
                    //_.each(_.where(success, { "status": $scope.manageCourse.showOfferingsWithStatus }).slice(0, 20), function(o) {
                    //    $scope.displayedOfferings.push(o);
                    //});

                    _this.setOfferingInstructors();
                    _this.setSessions(success);

                    _.each($scope.manageCourse.course.offerings, function (offering) {
                        offering.hasError = false;
                        _this.setOfferingAccordionHeading(offering);
                    });

                    _this.SetCourseEndDateBoundaries();
                    _this.SetCourseStartDateBoundaries();

                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    if (failure.ErrorMessage !== "There are no items to display.") {
                        $scope.manageCourse.editCourseOfferingsError.setError(errorCode, message);
                    }

                });


                $q.all(offeringsMetaDataPromises).then(function (success) {
                    // Only remove loading bars when all promises are done
                    $scope.manageCourse.editOfferingsIsLoading = false;
                    $scope.manageCourse.showOfferingsLoading = false;
                    $scope.manageCourse.isLoading = false;
                }, function (failure) {
                    // Or fail
                    $scope.manageCourse.editOfferingsIsLoading = false;
                    $scope.manageCourse.showOfferingsLoading = false;
                    $scope.manageCourse.isLoading = false;
                });

                $scope.manageCourse.getCourseSessions();
            };

            $scope.$on('$includeContentLoaded', function (event, url) {

                if (url === 'app/templates/courses/create/partials/step4/partials/admin.step4.offeringRepeater.html') {
                    $scope.manageCourse.showOfferingsLoading = false;
                    return;
                }
            });

            $scope.manageCourse.offeringIsDirty = function (offering, i) {
                if (offering.accordionOpen === true) {
                    $scope.manageCourse.stepFive.sessionCreator.offering = offering.localId;
                    offering.isDirty = true;

                    if ($scope.manageCourse.offeringsForm['registrationEndDateTime-' + i].$invalid) {
                        if (offering.registrationEndDateTime.moment < offering.maxRegistrationEndDateTime) {
                            $scope.manageCourse.offeringsForm['registrationEndDateTime-' + i].$setValidity('maxDate', true);
                        }
                    }

                    offering.hasError = ($scope.manageCourse.offeringsForm['name-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['description-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['location-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['minimumEnrollment-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['maxAllowedSize-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['enrollmentOpen-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['registrationEndDateTime-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['cancelDays-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['waitlistDays-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['MaxWaitlistSize-' + i].$invalid ||
                        $scope.manageCourse.offeringsForm['instructors-reader-' + i].$invalid);
                    $scope.manageCourse.course.hasInvalidOfferings = typeof _.findWhere($scope.manageCourse.course.offerings, { 'hasError': true }) !== "undefined";
                    var offCheck = _this.getOfferingwithNoSession();
                    $scope.manageCourse.course.hasOfferingWithNoSession = typeof offCheck.localId !== "undefined";
                    _this.setIltSessionMinMaxDates();

                }
            };

            _this.setSessions = function (offerings) {
                var course = $scope.manageCourse.course;
                var allSessions = [];

                _.each(offerings, function (offering) {
                    offering.localId = offering.id;
                    _.each(offering.sessions, function (session) {
                        session.offeringName = offering.name;
                        session.offeringId = offering.id;
                        session.startDate = session.startDateTime.jsDate;
                        session.startTime = session.startDateTime.jsDate;
                        session.endDate = session.endDateTime.jsDate;
                        session.endTime = session.endDateTime.jsDate;
                        session.error = false;
                        session.offeringStatus = offering.status;
                        allSessions.push(session);
                    });
                });

                $scope.manageCourse.stepFive.allSessions = allSessions;
            };

            _this.setOfferingInstructors = function () {
                var instructors = $scope.manageCourse.stepFour.instructors;
                var courseOfferings = $scope.manageCourse.course.offerings;

                if (typeof courseOfferings !== 'undefined' && courseOfferings !== null) {

                    _.each(courseOfferings, function (offer) {
                        offer.instructorPills = [];
                        if (typeof offer.instructorIds !== 'undefined' && offer.instructorIds !== null && offer.instructorIds.length > 0) {
                            _.each(offer.instructorIds, function (inst) {
                                var matchInstr = _.findWhere(instructors, { id: inst });
                                if (typeof matchInstr !== 'undefined') {
                                    offer.instructorPills.push(matchInstr);
                                }
                                else {
                                    //get the inactive instructor by id
                                    var inactiveInstructor = UsersService.fetch(inst);

                                    inactiveInstructor.then(function (response) {
                                        offer.instructorPills.push(response);
                                    });
                                }


                            });
                        }
                    });
                }
            };


            _this.batchUpdateUserInRoster = function (registerToRoster, offering) {

                $scope.manageCourse.stepSix.rosterSaving = true;

                var updateRosterStatus = RosterService.updateMultiplePersonOnRoster(registerToRoster);
                updateRosterStatus.then(function (success) {

                    var successCalls = _.where(success, { success: true });
                    var failedCalls = _.where(success, { success: false });

                    //var offering = _.findWhere($scope.manageCourse.course.offerings, { id: registerToRoster[0].OfferingId });
                    var roster = offering.roster.attendingStudents;

                    _.each(successCalls, function (s) {
                        var successUser = _.findWhere(roster, { id: s.studentId });
                        //prevents users from being resubmitted
                        successUser.isExisting = true;

                        successUser.success = messagingDisplayerUtil.create();
                        successUser.success.setError(200, 'Successfully added ' + successUser.fullName + ' to roster!', false);
                    });

                    _.each(failedCalls, function (f) {
                        var failedUser = _.findWhere(roster, { id: f.studentId });
                        failedUser.error.setError("", f.message);
                    });

                    if (typeof failedCalls !== 'undefined' && failedCalls !== null && failedCalls.length > 0) {
                        hasOfferingErrors = true;
                        $scope.manageCourse.editCourseOfferingsRosterError.setError("", "Roster Error: There were errors saving roster changes for " + offering.name);
                    }

                    _this.setOfferingAccordionHeading(offering);
                    $scope.manageCourse.stepSix.rosterSaving = false;

                }, function (failure) {
                    hasOfferingErrors = true;
                    $scope.manageCourse.editCourseOfferingsRosterError.setError("", offering.name + "Roster Error: There were errors saving roster changes for " + offering.name);
                    _.each(offering.roster.attendingStudents, function (r) {
                        $scope.manageCourse.singleUserToRosterError(failure, r);
                    });
                    $scope.manageCourse.stepSix.rosterSaving = false;
                });
            };



            $scope.manageCourse.getCourseSessions = function () {
                var sessions = $scope.manageCourse.stepFive.allSessions;

                $scope.manageCourse.stepFive.repeatOptions = [{
                    id: 1,
                    label: 'Daily'
                }, {
                    id: 2,
                    label: 'Weekly'
                }, {
                    id: 4,
                    label: 'Monthly'
                }, {
                    id: 5,
                    label: 'Yearly'
                }];
            };

            $scope.manageCourse.addMoreItems = function () {

                var last = $scope.displayedOfferings.length;
                var offeringsWithStatus = _.where($scope.manageCourse.course.offerings, { "status": $scope.manageCourse.showOfferingsWithStatus });

                if (last < offeringsWithStatus.length) {
                    _.each(offeringsWithStatus.slice(last, last + 20), function (o) {
                        $scope.displayedOfferings.push(o);
                    });
                }

            };

            // ASSUMPTION: Offerings are already loaded
            $scope.manageCourse.getCourseOfferingsRoster = function () {
                var offerings = $scope.manageCourse.course.offerings;
                $scope.manageCourse.editRosterIsLoading = true;
                if (typeof offerings !== 'undefined' && offerings.length > 0) {
                    _.each(offerings, function (offering) {
                        offering.search = {};
                        offering.search.error = messagingDisplayerUtil.create();

                        var roster = offering.roster.attendingStudents;
                        _.each(roster, function (user) {
                            user.isExisting = true;
                        });
                    });
                }

                $scope.manageCourse.editRosterIsLoading = false;
            };

            $scope.manageCourse.refreshCourseOfferingsRoster = function () {
                var offerings = $scope.manageCourse.course.offerings;
                $scope.manageCourse.editRosterIsLoading = true;
                if (typeof offerings !== 'undefined' && offerings.length > 0) {
                    _.each(offerings, function (offering) {
                        offering.search = {};
                        offering.search.error = messagingDisplayerUtil.create();

                        var singleRosterPromise = RosterService.fetch(offering.id, 'ilt');
                        singleRosterPromise.then(function (success) {
                            if (typeof success !== 'undefined' && success !== null) {
                                offering.roster = {};
                                offering.roster.attendingStudents = [];

                                var attendingStudents = success.attendingStudents;
                                if (attendingStudents.length > 0) {
                                    _.each(success.attendingStudents, function (attendingStudent) {
                                        attendingStudent.student.isExisting = true;
                                        offering.roster.attendingStudents.push(attendingStudent.student);
                                    });
                                }
                            }
                        }, function (failure) {
                            console.log(failure);
                        });
                    });
                }

                $scope.manageCourse.editRosterIsLoading = false;

            };

            /* build the tree */
            /* also handles Custom Content */
            _this.baiCatalogCta = function () {
                $scope.baiCatalogError.error.reset();

                var baiCatalogPromise = CatalogService.getBaiCatalogData();

                baiCatalogPromise.then(function (success) {
                    _this.baiCatalogPromiseData = success;

                    //Make a copy of the tree data so that manipulation of it does not flow to the service
                    var catalogTreeDataCopy = angular.copy(success.formattedCatalogTreeData);
                    $scope.manageCourse.categoryRows = success.formattedCatalogTree;
                    TreeHelpers.setPropertyOnAllNodes(catalogTreeDataCopy, 'lockIcon', true);

                    //If the org has a custom catalog, remove it from the BAI Catalog and put all nodes in the Custom Content section
                    var custContIdx = _.findIndex(catalogTreeDataCopy, { "name": "Custom Content" });
                    if (custContIdx >= 0) {
                        $scope.customCatalogData = [{ 'name': 'Custom Content', 'nodes': catalogTreeDataCopy[custContIdx].nodes }];
                        catalogTreeDataCopy.splice(custContIdx, 1);
                    }

                    var custDocIdx = _.findIndex(catalogTreeDataCopy, { "name": "Custom Documents" });
                    if (custDocIdx >= 0) {
                        $scope.customDocumentsCatalogData = [{ 'name': 'Custom Documents', 'nodes': catalogTreeDataCopy[custDocIdx].nodes }];
                        catalogTreeDataCopy.splice(custDocIdx, 1);
                        var policiesIdx = _.findIndex(catalogTreeDataCopy, { "name": "Policies" });
                        if (policiesIdx >= 0 && $scope.customDocumentsCatalogData.length > 0) { $scope.customDocumentsCatalogData[0].nodes.push(catalogTreeDataCopy[policiesIdx]); }
                    }

                    //TODO:LXPDoc:CustomDocuments - need to filter out custom document nodes
                    $scope.documentTreeData = catalogTreeDataCopy.filter(function (taxon) {
                        return taxon.catalogNodeType.toLowerCase() === "document";
                    });

                    _this.shouldShowDocuments();

                    catalogTreeDataCopy = catalogTreeDataCopy.filter(function (taxon) {
                        return taxon.catalogNodeType.toLowerCase() !== "document";
                    });

                    $scope.baiCatalogData = [{ 'name': 'BAI Catalog', 'nodes': catalogTreeDataCopy }];
                    $scope.baiCatalogIsLoading = false;
                    $scope.coursesSelected = $scope.baiCatalogData[0];

                    $scope.baiDocumentsCatalogData = [{ 'name': 'BAI Documents', 'nodes': $scope.documentTreeData }];

                }, function (failure) {
                    $scope.baiCatalogIsLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.baiCatalogError.error.setError(errorCode, message);
                });
            };

            /* This gets called when you click on any node in the tree.
             * Filter the items on the right when you click on the node
             */
            $scope.catalogNodeClick = function (item, catalogParentNode) {

                //clear out the search value since we aren't using it
                $scope.general.search = "";

                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });

                //default to bai courses 
                $scope.coursesSelected = _this.baiCourses;
                if (catalogParentNode === "custom") {
                    $scope.coursesSelected = _this.customCourses;
                } else if (catalogParentNode === "documents") {
                    $scope.coursesSelected = _this.baiDocuments;
                } else if (catalogParentNode === "customDocuments") {
                    $scope.coursesSelected = _this.customDocuments;
                }

                $scope.rowsAreLoading = true;
                var rows = [];

                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {

                    if (typeof item.nodeId === 'undefined') {
                        rows = $scope.coursesSelected;

                    } else {
                        _this.emptyArray = [];
                        rows = _this.filterRowsByCatalogId(item);
                    }

                    _this.currentCourseSet = rows;

                    /**
                     * Reset page size and info for paginator
                     */
                    _this.calculatePages(rows);

                    var copy = angular.copy(rows);

                    rows = copy.splice(0, $scope.home.limit);

                    $scope.rows = rows;
                    $scope.rowsAreLoading = false;
                } else {
                    $location.path('/courses/home');
                }
            };

            /* function to check if the documents catalog node should show */
            _this.shouldShowDocuments = function () {
                /*function is called in multiple areas so that there are no race conditions */
                $scope.showDocumentsCatalog = false;
                if ($scope.isDocumentsEnabled && $scope.documentTreeData !== undefined && $scope.documentTreeData.length > 0) {
                    $scope.showDocumentsCatalog = true;
                }
            };

            //Inactive tab Click event to get the inactive records
            $scope.getInactiveCourses = function () {
                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });
                $scope.rowsAreLoading = true;
                $scope.courseactivetab = true;
                //console.log('Inactive Button Click');
                var allInactiveCoursesData = CoursesService.getInactiveCoursesForOrg();

                return allInactiveCoursesData.then(function (success) {
                    $scope.rowsAreLoading = false;

                    _this.allCoursesData = _.sortBy(success, $scope.propertyName);
                    var rows = [];
                    rows = _this.allCoursesData;
                    _this.currentCourseSet = rows;
                    _this.customCourses = $filter('filter')(_this.currentCourseSet, { baiOnly: false });
                    _this.baiDocuments = _this.currentCourseSet.filter(function (c) { return c.baiOnly && c.normalizedProductType === "document"; });
                    _this.baiCourses = _this.currentCourseSet.filter(function (c) { return c.baiOnly && c.normalizedProductType !== "document"; });
                    _this.customDocuments = _this.currentCourseSet.filter(function (c) { return !c.baiOnly && c.normalizedProductType === "document"; });
                    /**
                     * Reset page size and info for paginator
                     */
                    _this.calculatePages(rows);
                    var copy = angular.copy(rows);
                    rows = copy.splice(0, $scope.home.limit);
                    $scope.rows = rows;
                    //$scope.manageCourse.evaluationsNsurveys = _this.getEvaluations(copy);
                }, function (failure) {
                    $scope.baiCatalogIsLoading = false;
                    $scope.rowsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.assignmentTableError.error.setError(errorCode, message);
                });
            };

            $scope.getActiveCourses = function () {
                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });
                $scope.courseactivetab = false;
                $scope.showcoursesactivetab = true;
                $scope.rowsAreLoading = true;
                var allActiveCoursesData = CoursesService.getActiveCoursesForOrg(false, true);

                return allActiveCoursesData.then(function (success) {
                    $scope.rowsAreLoading = false;
                    _this.createCourseArrays(success);

                }, function (failure) {
                    $scope.baiCatalogIsLoading = false;
                    $scope.rowsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.assignmentTableError.error.setError(errorCode, message);
                });
            };

            /********* CREATE ASSIGNMENT ACTION **********/
            ///////// GLOBAL VARIABLES

            $scope.createAction = function () {
                $scope.homeTemplate = 'app/templates/courses/create/admin.html';
                $scope.wizardTitle = "Add New Content";

                $scope.manageCourse.popoverIsOpen = false;

                $scope.create = {};
                $scope.create.isLoading = true;
                $scope.showcoursesactivetab = false;

                $scope.manageCourse.ASSIGNMENT_TYPE_ENUM = [
                    { id: "WBT", code: "wbt", title: "Web Based Training" },
                    { id: "CERTIFICATION", code: "courseGroupCertification", title: "Certification" },
                    { id: "COURSEGROUP", code: "courseGroup", title: "Course Group" },
                    { id: "CURRICULA", code: "courseGroupCurricula", title: "Curricula" },
                    { id: "TASK", code: "task", title: "Task" },
                    { id: "EVALUATION", code: "evaluation", title: "Evaluation" },
                    { id: "SURVEY", code: "survey", title: "Survey" },
                    { id: "ASSESSMENT", code: "assessment", title: "Assessment" }
                ];

                _this._clearCourseCreateForm();
                _this.setEvaluations();
                _this.getContentTypes();
                $scope.$watch(function () {
                    return $rootScope.sysSettings;
                }, function () {
                    $scope.create.isLoading = false;

                    $scope.courses.isILTEnabled = SettingsService.isILTEnabled();
                    $scope.courses.isCareerPathEnabled = SettingsService.isCareerPathEnabled();
                    if ($scope.courses.isILTEnabled) {
                        $scope.manageCourse.ASSIGNMENT_TYPE_ENUM.splice(1, 0, { id: "ILT", code: "ilt", title: "Instructor Led Training" });
                        if ($scope.courses.isDocumentsEnabled === true) {
                            $scope.manageCourse.ASSIGNMENT_TYPE_ENUM.splice(2, 0, { id: "DOC", code: "doc", title: "Document" });

                            if ($scope.courses.isCareerPathEnabled === true) {
                                $scope.manageCourse.ASSIGNMENT_TYPE_ENUM.splice(3, 0, { id: "CAREERPATH", code: "careerPath", title: "Career Path" });
                            }
                        }
                        else {
                            if ($scope.courses.isCareerPathEnabled === true) {
                                $scope.manageCourse.ASSIGNMENT_TYPE_ENUM.splice(2, 0, { id: "CAREERPATH", code: "careerPath", title: "Career Path" });
                            }
                        }
                    }
                    else {
                        $scope.manageCourse.ASSIGNMENT_TYPE_ENUM = [];
                        $scope.manageCourse.ASSIGNMENT_TYPE_ENUM.push({ id: "DOC", code: "doc", title: "Document" });
                    }
                });
            };

            $scope.manageCourse.currentAssignmentType = null;
            $scope.manageCourse.course = {};
            $scope.manageCourse.error = messagingDisplayerUtil.create();
            $scope.manageCourse.isLoading = false;
            $scope.manageCourse.scormPackages = [];
            $scope.manageCourse.urlUpload = null;
            $scope.manageCourse.documentUpload = null;
            $scope.offeringInUse = {};//SLY-3974-Inactivate Save button if no session for an offering.

            /**
             * Insert an instructor into the appropirate offering
             *
             * @param $item {obj} Selected item
             * @param $model {obj} Selected item
             * @param $label {string} Label that contains selected info
             * @param $event {obj} Click event
             * @param offering {obj}
             */
            $scope.manageCourse.selectInstructor = function ($item, $model, $label, $event, offering) {
                offering.instructorReader = $label;

                offering.instructorLabel = '';

                offering.instructorIds.push($item.id);
                offering.instructorPills.push($item);
            };

            /**
             * Attach appropriate location info to offering
             *
             * @param offering {obj} Offering to have location data inserted into
             */
            $scope.manageCourse.selectLocation = function (offering) {
                var location = _.find($scope.manageCourse.locations, function (location) {
                    return location.id === offering.facilityId;
                });

                offering.location = location.name;
                offering.address = location.mailingAddress;

                _this.setOfferingAccordionHeading(offering);
            };

            /**
             * Create a new offering
             */
            $scope.manageCourse.cloneOffering = function () {

                var courseUpdateMethod = $route.current.params.name;
                if (typeof courseUpdateMethod !== 'undefined' && courseUpdateMethod === 'edit') {
                    if (typeof $scope.manageCourse.course.offerings === 'undefined') {
                        $scope.manageCourse.course.offerings = [];
                    }
                }

                var offering = OfferingsService.createGenericOffering($scope.manageCourse.course.offerings.length + 1, $scope.manageCourse.course.id);
                _this.setOfferingEnrollmentOpensDateBoundries(offering); // This is to initialize the enrollment open dates and date bounds of the new offering
                _this.setOfferingEnrollmentEndDateBoundries(offering); // This is to initialize the enrollment closes dates and date bounds of the new offering
                offering.accordionOpen = true;
                $scope.offeringInUse = offering;//SLY-3974-Inactivate Save button if no session for an offering.
                $scope.manageCourse.course.hasInvalidOfferings = true;
                _this.setNewOfferingDefaults(offering);

                var today = dateUtil.setPortalStartTime(new Date());

                if (today >= $scope.manageCourse.course.displayStart.jsDate) {
                    offering.requestedOn = dateUtil.formatDate(today);
                } else {
                    offering.requestedOn = $scope.manageCourse.course.displayStart;
                }

                _this.setOfferingAccordionHeading(offering);

                $scope.manageCourse.showOfferingsWithStatus = "open";
                $scope.manageCourse.displayOfferingsWithStatus();

                $scope.displayedOfferings.unshift(offering);
                $scope.manageCourse.course.offerings.unshift(offering);

                $scope.manageCourse.stepFive.sessionCreator.offering = offering.localId;
            };

            /**
             * Remove instructors from appropriate lists
             *
             * @param offering {obj} Offering to be modified
             * @param index {int} Index to be removed from instructor lists
             */
            $scope.manageCourse.removeUserForInstructorList = function (offering, index) {
                offering.instructorIds.splice(index, 1);
                offering.instructorPills.splice(index, 1);

                if (offering.instructorPills.length === 0) {
                    offering.instructorLabel = '';
                    offering.instructorReader = '';
                }
            };

            /**
             * Delete an offering
             *
             * @param $index {int} Index of offering
             */
            $scope.manageCourse.removeOffering = function ($index, offering) {

                var offeringUpdateMethod = $route.current.params.name;
                if (typeof offeringUpdateMethod !== 'undefined' && (offeringUpdateMethod === 'edit' || offeringUpdateMethod === 'create')) {

                    if (typeof offering.id === 'undefined') {
                        $scope.manageCourse.course.offerings.splice($index, 1);
                        $scope.displayedOfferings.splice($index, 1);

                        if (typeof $scope.manageCourse.stepFive.allSessions !== 'undefined' && $scope.manageCourse.stepFive.allSessions.length > 0) {
                            var allsessionscopy = ($scope.manageCourse.stepFive.allSessions);
                            _.each(allsessionscopy, function (session) {
                                if (session.offeringId === offering.localId) {
                                    var sessionKey = $scope.manageCourse.stepFive.allSessions.indexOf(session);
                                    $scope.manageCourse.stepFive.allSessions.splice(sessionKey, 1);
                                }
                            });
                        }

                    } else {
                        _this.removeOffering(offering);
                    }
                }

                //SLY-3974-Inactivate Save button if no session for an offering.
                $scope.offeringInUse = _this.getOfferingwithNoSession();
                if (typeof $scope.offeringInUse.localId === "undefined") {
                    $scope.manageCourse.course.hasOfferingWithNoSession = false;

                    $scope.manageCourse.course.hasInvalidOfferings = typeof _.findWhere($scope.manageCourse.course.offerings, { 'hasError': true }) !== "undefined";
                }
            };

            _this.removeOffering = function (offering) {
                var removeOfferingPromise = OfferingsService.cancelOffering(offering.id, 'ilt');
                removeOfferingPromise.then(function (success) {
                    offering.status = "cancelled";
                    if (typeof $scope.manageCourse.stepFive.allSessions !== 'undefined' && $scope.manageCourse.stepFive.allSessions.length > 0) {
                        var allsessionscopy = ($scope.manageCourse.stepFive.allSessions);
                        _.each(allsessionscopy, function (session) {
                            if (session.offeringId === offering.id) {
                                session.offeringStatus = "cancelled";
                            }
                        });
                    }
                    _this.setOfferingAccordionHeading(offering);
                    $scope.manageCourse.displayOfferingsWithStatus();

                }, function (failure) {
                    console.log(failure);
                });
            };

            /**
             * Keep track of all days that need to be repeated over
             *
             * @param day
             * @param $event
             */
            $scope.manageCourse.selectDayForSessionCreator = function (day, $event) {
                if (jQuery($event.currentTarget).hasClass('active')) {
                    var index = $scope.manageCourse.stepFive.sessionCreator.days.indexOf(day);

                    $scope.manageCourse.stepFive.sessionCreator.days.splice(index, 1);
                } else {
                    $scope.manageCourse.stepFive.sessionCreator.days.push(day);
                }

                $scope.manageCourse.stepFive.sessionCreator.day = day;

                jQuery($event.currentTarget).toggleClass('active');
            };

            /**
             * Create all sessions.
             */
            $scope.manageCourse.createSessions = function () {
                var sessionCreator = angular.copy($scope.manageCourse.stepFive.sessionCreator);

                var sessions = [];

                $scope.manageCourse.stepFive.error.reset();
                $scope.manageCourse.stepFive.errorCount = 0;

                if (sessionCreator.startDate.moment.isAfter(sessionCreator.endDate.moment)) {
                    $scope.manageCourse.stepFive.error.setError(500, 'No sessions added. Start date must be less than end date');
                    return;
                }

                /**
                 * Three flows:
                 *
                 * 1. Check if frequency is set. If it is, ignore dates; just create sessions on frequency * number
                 * of repetitions
                 * 2. Days were selected. Repeat number of weeks with days.
                 * 3. One session is being created. Carry on.
                 */
                switch (sessionCreator.repeat) {
                    // daily
                    case 1:
                        sessions = sessionFormatterUtil.createSessionsByStandardRepeat(sessionCreator, $scope.manageCourse.course.offerings, 'days', 1);

                        break;
                    case 2:
                        sessions = sessionFormatterUtil.createSessionsByRepeatingDays(sessionCreator, $scope.manageCourse.course.offerings);

                        break;
                    case 3:
                        sessions = sessionFormatterUtil.createSessionsByStandardRepeat(sessionCreator, $scope.manageCourse.course.offerings, 'weeks', 2);

                        break;
                    case 4:
                        sessions = sessionFormatterUtil.createSessionsByStandardRepeat(sessionCreator, $scope.manageCourse.course.offerings, 'months', 1);

                        break;
                    case 5:
                        sessions = sessionFormatterUtil.createSessionsByStandardRepeat(sessionCreator, $scope.manageCourse.course.offerings, 'years', 1);

                        break;
                }

                /**
                 * Get total count of errors; filter out the session
                 * concatenation.
                 */
                sessions = _.filter(sessions, function (session) {
                    session.offeringStatus = 'open';
                    if (session.error === true) {
                        $scope.manageCourse.stepFive.errorCount++;
                    } else {
                        return session;
                    }
                });

                if ($scope.manageCourse.stepFive.errorCount > 0) {
                    $scope.manageCourse.stepFive.error.setError(500, $scope.manageCourse.stepFive.errorCount + ' sessions were not added, due to date time conflicts!');
                }

                if (typeof $scope.manageCourse.stepFive.allSessions !== 'undefined') {
                    $scope.manageCourse.stepFive.allSessions = _.sortBy($scope.manageCourse.stepFive.allSessions.concat(sessions), 'startDate');
                }
                else {
                    $scope.manageCourse.stepFive.allSessions = sessions;
                }

                var offering = _.find($scope.manageCourse.course.offerings, function (offering) {
                    return offering.localId === sessionCreator.offering;
                });

                if (typeof offering.sessions !== 'undefined' && offering.sessions !== null) {
                    offering.sessions = _.sortBy(offering.sessions.concat(sessions), 'startDate');
                } else {
                    offering.sessions = sessions;
                }

                _this.setOfferingEnrollmentEndDateBoundries(offering);
                if (offering.registrationEndDateTime === undefined || offering.registrationEndDateTime === null) {
                    offering.registrationEndDateTime = dateUtil.formatDate(offering.maxRegistrationEndDateTime);
                }

                _this.setOfferingAccordionHeading(offering);
                offering.isDirty = true;
            };

            /**
             * Get the appropriate offering name
             *
             * @param id {int} ID of selected offering
             */
            // $scope.manageCourse.getOfferingName = function(id) {
            //     var offering = _.find($scope.manageCourse.stepFive.allSessions, function(offering) {
            //         return parseInt(offering.localId, 10) === parseInt(id, 10);
            //     });

            //     return offering.name;

            // };

            /**
             * Remove a session from an offering
             *
             * @param session {obj} session to be removed
             */
            $scope.manageCourse.removeSession = function (session) {
                var offering = _.find($scope.manageCourse.course.offerings, function (offering) {
                    return parseInt(offering.localId, 10) === parseInt(session.offeringId, 10);
                });

                /**
                 * Remove session off of the offering
                 *
                 * @type {*}
                 */
                var sessionKey = offering.sessions.indexOf(session);

                offering.sessions.splice(sessionKey, 1);
                $scope.offeringInUse = offering;//SLY-3974-Inactivate Save button if no session for an offering.
                /**
                 * Remove the session off of the general list of sessions
                 */
                sessionKey = $scope.manageCourse.stepFive.allSessions.indexOf(session);

                $scope.manageCourse.stepFive.allSessions.splice(sessionKey, 1);

                _this.setOfferingAccordionHeading(offering);
                offering.isDirty = true;
            };

            /**
             * Send the offering data to the offering service for creation
             */
            $scope.manageCourse.createOfferingsCall = function () {
                $scope.manageCourse.stepFive.error.reset();
                var i = 0;
                for (i = 0; i < $scope.manageCourse.course.offerings.length; i++) {
                    if ($scope.manageCourse.course.offerings[i].sessions.length === 0) {
                        $scope.manageCourse.stepFive.error.setError("", "All offerings must have sessions prior to saving");
                        $scope.manageCourse.onSessionsTab = true;
                        return;
                    }
                }

                $scope.manageCourse.stepFive.isLoading = true;

                // Find all offerings that need to be created or updated
                var offeringsToCreate = [];
                var offeringsToUpdate = [];
                for (i = 0; i < $scope.manageCourse.course.offerings.length; i++) {
                    if (typeof $scope.manageCourse.course.offerings[i].id === 'undefined' || $scope.manageCourse.course.offerings[i].id === 0) {
                        offeringsToCreate.push($scope.manageCourse.course.offerings[i]);
                    } else {
                        if ($scope.manageCourse.course.offerings[i].isDirty) {
                            offeringsToUpdate.push($scope.manageCourse.course.offerings[i]);
                        }
                    }
                }

                var allOfferingsPromises = [];
                var errormessage = "";

                if (offeringsToCreate.length > 0) {
                    // Copy offerings to create to not disrupt the UI and setup create promise in offerings service
                    var offerings = angular.copy(offeringsToCreate);
                    /* To update the Format with Time */
                    for (k = 0; k < offerings.length; k++) {
                        if (offerings[k].requestedOn !== undefined) {
                            offerings[k].requestedOn = dateUtil.formatDate(new Date(offerings[k].requestedOn.fullDateWithTime));
                        }
                        if (offerings[k].registrationEndDateTime === undefined || offerings[k].registrationEndDateTime === null) {
                            offerings[k].registrationEndDateTime = dateUtil.formatDate(offerings[k].maxRegistrationEndDateTime);
                        }

                        offerings[k].registrationEndDateTime = dateUtil.formatDate(new Date(offerings[k].registrationEndDateTime.fullDateWithTime));
                    }
                    /* End */
                    var createOfferingsDeferred = $q.defer();
                    var createOfferingsPromise = OfferingsService.createOfferings(offerings);

                    // Handle the create offerings return
                    createOfferingsPromise.then(function (success) {

                        _.each(success, function (item) {

                            if (item.status === "Success") {
                                var single = item.offering;

                                var nOffering = _.findWhere($scope.manageCourse.course.offerings, { localId: single.localId, name: single.name });
                                nOffering.isDirty = false;
                                nOffering.id = single.id;
                                nOffering.code = single.code;
                                // Update UI sessions with ids from sessions in API response
                                _.each(nOffering.sessions, function (session) {
                                    // Find each api session where start date matches UI session start date and end date matches UI session end date
                                    var apiSession = _.find(single.sessions, function (s) {
                                        var sd = new Date(s.startDateTime);
                                        var ed = new Date(s.endDateTime);
                                        return session.startDate.toLocaleString() === sd.toLocaleString() && session.endDate.toLocaleString() === ed.toLocaleString();
                                    });
                                    // Set UI session id = api session id. This is needed for saving so the API knows what is new or existing.
                                    session.id = apiSession.id;
                                });

                            } else {
                                errormessage = errormessage === "" ? item.status : errormessage + "\r\n" + item.status;
                            }
                        });
                        if (errormessage !== "") {
                            $scope.manageCourse.editCourseOfferingsError.setError("", errormessage);
                        }

                        createOfferingsDeferred.resolve();

                    }, function (failure) {
                        errormessage = errormessage === "" ? failure.ErrorMessage : errormessage + "\r\n" + failure.ErrorMessage;
                        createOfferingsDeferred.resolve();
                    });

                    allOfferingsPromises.push(createOfferingsDeferred.promise);
                }

                if (offeringsToUpdate.length > 0) {
                    var updateOfferingsDeferred = $q.defer();
                    // Setup promises for all offerings that need to be updated
                    var updateOfferingsPromise = OfferingsService.updateOfferingsBatch(offeringsToUpdate);

                    // For each offering that needs to be updated, handle the response
                    updateOfferingsPromise.then(function (success) {
                        _.each(success, function (item) {
                            if (item.status === "Success") {
                                var single = item.offering;

                                var uiOfferingUpdated = _.find($scope.manageCourse.course.offerings, function (ofr) {
                                    return (typeof ofr.id !== "undefined" && ofr.id === single.id);
                                });

                                uiOfferingUpdated.isDirty = false;
                                uiOfferingUpdated.code = single.code;
                                // Update UI sessions with ids from sessions in API response
                                _.each(uiOfferingUpdated.sessions, function (session) {
                                    // Find each api session where start date matches UI session start date and end date matches UI session end date
                                    // Set UI session id = api session id
                                    var apiSession = _.find(single.sessions, function (s) {
                                        return session.startDateTime.toLocaleString() === s.startDateTime.toLocaleString() && session.endDateTime.toLocaleString() === s.endDateTime.toLocaleString();
                                    });

                                    session.id = apiSession.id;
                                });
                            } else {
                                errormessage = errormessage === "" ? item.status : errormessage + "\r\n" + item.status;
                            }
                        });

                        if (errormessage !== "") {
                            $scope.manageCourse.editCourseOfferingsError.setError("", errormessage);
                        }

                        updateOfferingsDeferred.resolve();

                    }, function (failure) {
                        errormessage = errormessage === "" ? failure.ErrorMessage : errormessage + "\r\n" + failure.ErrorMessage;
                        updateOfferingsDeferred.resolve();
                    });

                    allOfferingsPromises.push(updateOfferingsDeferred.promise);
                }

                $q.all(allOfferingsPromises).then(function (success) {
                    if (errormessage === "") {
                        $scope.manageCourse.stepFive.isLoading = false;
                        WizardHandler.wizard('Content').next();
                        $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(6);
                    } else {
                        $scope.manageCourse.onSessionsTab = true;
                        $scope.manageCourse.stepFive.isLoading = false;
                        $scope.manageCourse.stepFive.error.setError("", errormessage);
                    }
                });
            };

            $scope.manageCourse.maxEnrollmentAttemptsToggle = true;

            /* stores the course returned by the server
             * when we create a course on step 2 */
            $scope.manageCourse.createdCourse = null;
            $scope.manageCourse.documentList = [];
            //$scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(1);
            $scope.manageCourse.selectAssignment = function (assignmentType) {
                // Assigning this twice. Should probably refactor.
                $scope.manageCourse.currentAssignmentType = assignmentType;
                $scope.manageCourse.course.type = assignmentType;
                $scope.manageCourse.course.productType = "Course";
                switch (assignmentType) {
                    case 'wbt':
                        $scope.manageCourse.course.tileImage = 'grab_bag/0';
                        break;
                    case 'ilt':
                        $scope.manageCourse.course.tileImage = 'grab_bag/1';
                        break;
                    case 'vle':
                        break;
                    case 'task':
                        $scope.manageCourse.course.tileImage = 'grab_bag/2';
                        break;
                    case 'survey':
                        $scope.manageCourse.course.tileImage = 'grab_bag/3';
                        break;
                    case 'evaluation':
                        $scope.manageCourse.course.tileImage = 'grab_bag/4';
                        break;
                    case 'assessment':
                        $scope.manageCourse.course.tileImage = 'grab_bag/5';
                        break;
                    case 'courseGroup':
                    case 'courseGroupCurricula':
                    case 'courseGroupCertification':
                        $scope.manageCourse.course.tileImage = 'grab_bag/8';
                        break;
                    case 'careerPath':
                        $scope.manageCourse.course.tileImage = 'grab_bag/9';
                        $scope.manageCourse.newCareerPath = true;
                        break;
                    case 'doc':
                        $scope.manageCourse.course.tileImage = 'grab_bag/102';
                        $scope.manageCourse.course.productType = 'Document';
                        break;
                    default:
                        break;
                }
                var skillsPromise = SkillService.fetch();
                skillsPromise.then(function (success) {
                    $scope.availableSkills = success;
                }, function (failure) {

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.manageCourse.error.setError(errorCode, message);
                });
                var assignmentTypeIndex = _.findIndex($scope.manageCourse.ASSIGNMENT_TYPE_ENUM, { code: assignmentType });
                if (assignmentTypeIndex > -1) {
                    $scope.manageCourse.currentAssignmentTypeFullName = $scope.manageCourse.ASSIGNMENT_TYPE_ENUM[assignmentTypeIndex].title;
                }

                // Set a default value for the course code, format like 'wbt20170714141522923' with time including ms to encourage uniqueness
                $scope.manageCourse.course.code = ($scope.manageCourse.currentAssignmentType + (new Date()).toISOString().replace(/[^0-9]/g, ''));
                //Set Initial Radio Values for EA-2776
                $scope.manageCourse.course.visible = true;
                $scope.manageCourse.course.isManagerApprovalRequired = false;

                //EA-6351 For all course types - get list of custom categories to select from
                var categories = CategoriesService.fetch(null, false, true);

                categories.then(function (success) {
                    var filterby = $scope.manageCourse.currentAssignmentType.toLowerCase() === 'doc' ? 'document' : 'course';
                    if (filterby !== 'document') {
                        success = CategoriesService.RemoveDocumentPolicies(success);
                    }
                    $scope.taxonomyOptions = $filter('filter')(success, { categoryType: filterby });
                    $scope.subcategoryDisabled = true;
                    $scope.createUniqueCategoryList();

                }, function (error) {
                    $scope.manageCourse.stepTwo.error.setError(error.ErrorCode, error.ErrorMessage);
                });


                _this.setSecondaryProperties($scope.manageCourse.course.type);

                WizardHandler.wizard('Content').next();
                $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(2);
            };



            $scope.manageCourse.goBackToStep2 = function () {
                // delete $scope.manageCourse.course.id;
                // If we delete the courseId., evrytime user toggle between step 2-3 ., a new course
                // is created., so we shouldn't delete the courseid. see line 930., it is being used in that func

                WizardHandler.wizard('Content').previous();
                if (WizardHandler.wizard("Course").getEnabledSteps().length >= 3) {
                    WizardHandler.wizard("Course").getEnabledSteps()[2].selected = false;
                    WizardHandler.wizard("Course").getEnabledSteps()[2].completed = false;
                }
                $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(2);

                $scope.selectCategory();
            };

            /**
             * Jump to given step in wizard
             *
             * @param step {int} Step to go to
             */
            $scope.manageCourse.goTo = function (step) {
                WizardHandler.wizard('Content').goTo(step);
                WizardHandler.wizard("Course").getEnabledSteps()[step + 1].selected = false;
                WizardHandler.wizard("Course").getEnabledSteps()[step + 1].completed = false;
                $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(step + 1);

                return;
            };

            $scope.manageCourse.hideModal = function () {
                jQuery('#courseCreateSuccessModal').modal('hide');
                $timeout(function() {
                    $location.url('courses/home/');
                }, 400); // Wait for modal to finish leaving
            };

            _this._attachFiles = function (courseId, scormPackages, documentList, shellType) {
                var filesToUpload = [];

                if (typeof scormPackages !== 'undefined' && scormPackages.length > 0) {
                    for (var i = 0; i < scormPackages.length; i += 1) {
                        filesToUpload.push(CoursesService.uploadScormPackage(scormPackages[i], courseId, shellType));
                    }
                }

                if (typeof documentList !== 'undefined' && documentList.length > 0) {
                    for (var j = 0; j < documentList.length; j += 1) {
                        // Uploading a document from browser
                        if (documentList[j].fileOrUrl === 'file' && !documentList[j].fromRepo) {
                            //If this doc is set to launch with content, sequence the save so that the correct resourceID is saved to the course
                            if (documentList[j].launchWithContent === true) { $scope.sequenceCourseSave = true; }
                            filesToUpload.push(DocumentService.uploadDocument(documentList[j].fileObject, courseId, documentList[j].launchWithContent));
                        }

                        // Attaching document from repository
                        if (documentList[j].fileOrUrl === 'file' && documentList[j].fromRepo) {
                            filesToUpload.push(DocumentService.attachDocumentFromRepositoryToCourse(documentList[j].id, courseId));
                        }

                        // Attaching a URL
                        if (documentList[j].fileOrUrl === 'url') {
                            //If this url is set to launch with content, sequence the save so that the correct resourceID is saved to the course
                            if (documentList[j].launchWithContent === true) { $scope.sequenceCourseSave = true; }
                            filesToUpload.push(DocumentService.attachUrlToCourse(documentList[j].name, courseId, documentList[j].launchWithContent));
                        }
                    }
                }

                //See if there are any deleted files, if so, add the delete api call to the array
                if (typeof documentList !== 'undefined' && typeof documentList.deletedFiles !== 'undefined' && documentList.deletedFiles.length > 0) {
                    for (var e = 0; e < documentList.deletedFiles.length; e += 1) {
                        if (typeof documentList.deletedFiles[e].id !== 'undefined')
                            filesToUpload.push(DocumentService.deleteAsset(courseId, documentList.deletedFiles[e].id));
                    }
                }


                return $q.all(filesToUpload);
            };

            $scope.manageCourse.addScormPackage = function ($files) {
                $scope.isNotZipFile = false;

                if ($files.length === 0) {
                    return;
                }

                var file = $files[0];

                if (!(file.type === 'application/zip' || file.type === 'application/x-zip-compressed')) {
                    $scope.isNotZipFile = true;

                    return;
                }

                file.uniqueId = Date.now();

                $scope.manageCourse.scormPackages.push(file);
            };

            /* Called when you press the X button on the gray box
             * in the scorm package list */
            $scope.manageCourse.removeScormPackage = function (fileToRemove) {
                $scope.manageCourse.scormPackages = _.filter($scope.manageCourse.scormPackages, function (file) {
                    return (file.uniqueId !== fileToRemove.uniqueId);
                });
            };

            /* Called by $scope.createAction */
            _this._clearCourseCreateForm = function () {
                $scope.manageCourse.course = {};
                $scope.manageCourse.scormPackages = [];
                $scope.manageCourse.documentList = [];
                $scope.manageCourse.course.courseList = [];
                var jsStartDate = dateUtil.setPortalStartTime(new Date());
                $scope.manageCourse.course.displayStart = dateUtil.formatDate(jsStartDate);

                // Used to maintain all courses for course groups per SLY-3542
                $scope.manageCourse.previouslyAddedCourses = [];

                $scope.manageCourse.enrollmentAttemptsRegex = /^\d+$/;
            };

            _this._showWizardSuccessScreen = function () {
                $scope.manageCourse.isLoading = false;

                $scope.manageCourse.error.reset();

                jQuery('#courseCreateSuccessModal').modal();

                _this._clearCourseCreateForm();
            };

            // Used on the common properties page
            $scope.manageCourse.createCourse = function () {
                $scope.manageCourse.stepTwo.isLoading = true;
                $scope.manageCourse.error.reset();

                // This is used to promt the user with warning about skills changeing career path, disabling it so it affect the next step
                $scope.manageCourse.skillsUpdated = false;

                var courseId = $scope.manageCourse.course.id;
                var course;
                //Make a copy of the course object to update so that the manageCourse is not altered
                var courseToUpdate = angular.copy($scope.manageCourse.course);
                if (courseToUpdate.displayStart !== undefined && courseToUpdate.displayStart !== null)
                    courseToUpdate.displayStart = dateUtil.formatDate(new Date(courseToUpdate.displayStart.fullDate.toString() + ' 02:59 AM'));
                if (courseToUpdate.displayEnd !== undefined && courseToUpdate.displayEnd !== null)
                    courseToUpdate.displayEnd = dateUtil.formatDate(new Date(courseToUpdate.displayEnd.fullDate.toString() + ' 02:59 AM'));
                if (typeof courseId === 'undefined' || courseId === null) {
                    course = CoursesService.createCourse(courseToUpdate);
                } else if (courseId) {
                    course = _this.updateCourseCommonProp(courseToUpdate);
                }

                var courseLoadPromises = [course, SkillService.fetch()];

                $q.all(courseLoadPromises).then(function (allSuccess) {
                    var success = allSuccess[0];
                    $scope.availableSkills = allSuccess[1];
                    $scope.manageCourse.stepTwo.isLoading = false;
                    /**
                     * After moving sessions to proper place, overwriting the course object with the response causes
                     * functionally required nodes to disappear. Merging objects fixes this.
                     */
                    $scope.manageCourse.course = _.extend($scope.manageCourse.course, success);
                    if ($scope.manageCourse.course.type === 'wbt') {
                        $scope.manageCourse.course.shellType = 1;

                    }
                    var courseGroupTypes = [
                        'courseGroup',
                        'courseGroupCertification',
                        'courseGroupCurricula'
                    ];

                    if (jQuery.inArray($scope.manageCourse.course.type, courseGroupTypes) > -1) {
                        $scope.manageCourse.course.childCourses = angular.copy($scope.manageCourse.previouslyAddedCourses);
                    }

                    WizardHandler.wizard('Content').next();
                    $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(3);
                    /* after course creation - reload the courses for the TA Courses home */
                    CoursesService.getActiveCoursesForOrg(true, true);
                }, function (error) {
                    $scope.manageCourse.stepTwo.isLoading = false;

                    $scope.manageCourse.stepTwo.error.setError(error.ErrorCode, error.ErrorMessage);
                });
            };

            $scope.manageCourse.setNonScoring = function (value) {
                if (value) {
                    $scope.manageCourse.course.masteryScore = 0;
                }
            };

            /**
             *
             *  To set a proper mesage on cancel action for a respective step in course create.
             *  @param step {int} course step
             */
            _this.setCourseCancelMessage = function (step) {
                switch (step) {
                    case 2:
                        return "This will take you out of Course creation.\nDo you wish to continue?";
                    case 3:
                        return "Changes you made to this step will not be saved.\nDo you wish to continue?";
                    case 4:
                    case 5:
                    case 6:
                        return "Changes you made will not be saved.\nDo you wish to continue?";

                    default:
                        return "Are you sure want to cancel?";

                }
            };

            /**
             *
             *
             * @param type {string} Course type
             */
            _this.setSecondaryProperties = function (type) {
                _this._initAdditionalStep('Two', 'Properties');
                _this._initAdditionalStep('Three', 'Details');

                switch (type) {
                    case 'wbt':
                        $scope.manageCourse.course.score = null;

                        break;
                    case 'ilt':
                        _this._initAdditionalStep('Four', 'Offerings');
                        //_this._initAdditionalStep('Five', 'Sessions');
                        _this._initAdditionalStep('Six', 'Roster');

                        break;
                }
            };

            /**
             * Initialize extra steps for the wizard, in case more than three are needed
             *
             * @param step
             * @param name
             *
             * @private
             */
            _this._initAdditionalStep = function (step, name) {
                $scope.manageCourse['step' + step] = {};

                var currentStep = $scope.manageCourse['step' + step];

                currentStep.error = messagingDisplayerUtil.create();
                currentStep.isLoading = false;
                currentStep.allSessions = [];
                currentStep.errorCount = 0;
                currentStep.exists = true;
                currentStep.name = name;
            };

            /**
             * Create appropriate data for the fourth step. Only happens when fourth step exists.
             *
             * @private
             */
            $scope.manageCourse._stepFourAction = function () {
                switch ($scope.manageCourse.course.type) {
                    case 'ilt':
                        $scope.manageCourse.stepFour.autoCompleteError = false;

                        $scope.manageCourse.course.offerings = [];
                        var offering = OfferingsService.createGenericOffering(1, $scope.manageCourse.course.id);
                        offering.accordionOpen = true;

                        _this.setNewOfferingDefaults(offering);

                        _this.setOfferingAccordionHeading(offering);

                        $scope.manageCourse.course.offerings.push(offering);
                        /* step five has been merged into step four, so merge the inits */
                        $scope.manageCourse.initializeSessionCreator();

                        break;
                    default:
                        break;
                }
            };

            /**
             * Create appropriate data for the fifth step. Only happens when fifth step exists.
             *
             * @private
             */
            $scope.manageCourse.initializeSessionCreator = function () {
                switch ($scope.manageCourse.course.type) {
                    case 'ilt':
                        $scope.manageCourse.stepFive.sessionCreator = {};
                        $scope.ilt = {};

                        $scope.manageCourse.stepFive.sessionCreator.days = [];

                        $scope.manageCourse.stepFive.sessionCreator.endDate = null;

                        $scope.manageCourse.stepFive.sessionCreator.endTime = null;
                        $scope.manageCourse.stepFive.sessionCreator.startDate = null;
                        $scope.manageCourse.stepFive.sessionCreator.startTime = null;

                        $scope.manageCourse.stepFive.repeatOptions = [{
                            id: 1,
                            label: 'Daily'
                        }, {
                            id: 2,
                            label: 'Weekly'
                        }, {
                            // @TODO: @sfrohm - find out if Karen really wants bi-weekly.
                            //     id: 3,
                            //     label: 'Bi-weekly'
                            // }, {
                            id: 4,
                            label: 'Monthly'
                        }, {
                            id: 5,
                            label: 'Yearly'
                        }];
                        $scope.manageCourse.stepFive.sessionCreator.includeWeekdaysOnly = false;

                        break;
                    default:
                        break;
                } // end switch
            }; // end $scope.manageCourse.initializeSessionCreator

            /**
             * Create appropriate data for the sixth step. Only happens when sixth step exists.
             *
             * @private
             */
            $scope.manageCourse._stepSixAction = function () {
                switch ($scope.manageCourse.course.type) {
                    case 'ilt':
                        $scope.manageCourse.stepSix.currentCompletedTotal = 0;
                        $scope.manageCourse.stepSix.currentSuccessTotal = 0;
                        $scope.manageCourse.stepSix.totalCompletionsPossible = 0;

                        break;
                    default:
                        break;
                }
            };

            /**
             * Create or update the roster
             */
            $scope.manageCourse.addUsersToRosterCall = function () {
                /**
                 * Reset all values becuase we're restarting the calls
                 */
                $scope.manageCourse.stepSix.currentCompletedTotal = 0;
                $scope.manageCourse.stepSix.currentSuccessTotal = 0;
                $scope.manageCourse.stepSix.totalCompletionsPossible = 0;

                var offerings = $scope.manageCourse.course.offerings;

                var roster, user = null;

                $scope.manageCourse.stepSix.isLoading = true;

                for (var i = 0; i < offerings.length; i++) {
                    roster = offerings[i].roster.attendingStudents;

                    if (roster.length > 0) {
                        $scope.manageCourse.stepSix.totalCompletionsPossible += roster.length;

                        for (var j = 0; j < roster.length; j++) {
                            user = roster[j];

                            if (typeof user.error === "undefined" || user.error === null) {
                                user.error = messagingDisplayerUtil.create();
                            }
                            else {
                                user.error.reset();
                            }

                            if (typeof user.success === "undefined" || user.success === null) {
                                user.success = messagingDisplayerUtil.create();
                            } else if (user.success.thrown === true) {
                                /**
                                 * Make this one less because one person already succeeded. Otherwise,
                                 * we'll be in an infinite loop.
                                 */
                                $scope.manageCourse.stepSix.totalCompletionsPossible--;

                                continue;
                            }

                            _this.updateUserInRoster(offerings[i], user);
                        }
                    }
                }

                /**
                 * Run the completion state anyways. No one was added to the roster, which is valid.
                 */
                if ($scope.manageCourse.stepSix.totalCompletionsPossible === 0) {
                    _this.trackUserToRosterCompletions();
                }
            };

            _this.updateUserInRoster = function (offering, user) {
                user.isLoading = true;

                user.call = RosterService.updateSinglePersonOnRoster(user.id, offering.id, 'confirmed', 'ilt');
                user.call.then(function (success) {
                    $scope.manageCourse.stepSix.currentCompletedTotal++;
                    $scope.manageCourse.stepSix.currentSuccessTotal++;

                    $scope.manageCourse.singleUserToRosterSuccess(user);
                }, function (failure) {
                    $scope.manageCourse.stepSix.currentCompletedTotal++;

                    $scope.manageCourse.singleUserToRosterError(failure, user);
                });
            };

            $scope.manageCourse.singleUserToRosterError = function (failure, user) {
                user.isLoading = false;

                user.error.setError(failure.ErrorCode, failure.ErrorMessage);

                _this.trackUserToRosterCompletions();
            };

            $scope.manageCourse.singleUserToRosterSuccess = function (user) {
                user.isLoading = false;

                user.success.setError(200, 'Successfully added ' + user.fullName + ' to roster!');

                _this.trackUserToRosterCompletions();
            };

            _this.trackUserToRosterCompletions = function () {
                $scope.manageCourse.stepSix.isLoading = false;

                if ($scope.manageCourse.stepSix.currentSuccessTotal === $scope.manageCourse.stepSix.totalCompletionsPossible) {
                    jQuery('#courseCreateSuccessModal').modal();
                } else if ($scope.manageCourse.stepSix.currentCompletedTotal === $scope.manageCourse.stepSix.totalCompletionsPossible) {

                }
            };

            $scope.manageCourse.getTotalSessionsForAllOfferings = function () {
                var offerings = $scope.manageCourse.course.offerings;

                var totalSessions = 0;

                _.each(offerings, function (offering) {
                    totalSessions += offering.sessions.length;
                });

                return totalSessions;
            };

            /**
             * Search for users
             *
             * @param value {string} Search value
             * @param offering {obj} Offering whose roster will be used to filter already selected users
             */
            $scope.manageCourse.searchForUsers = function (value, offering) {
                if (typeof offering.search === "undefined") {
                    offering.search = {};
                    offering.search.error = messagingDisplayerUtil.create();
                }
                offering.search.error.reset();
                offering.search.isLoading = true;

                var searchAttributes = {};
                searchAttributes.pageNumber = 1;
                searchAttributes.pageSize = 25;
                searchAttributes.fullNameContains = value;

                var users = UsersService.advancedSearch(searchAttributes);

                //var users = UsersService.search(value);
                return users.then(function (success) {
                    offering.search.isLoading = false;

                    if (offering.roster.attendingStudents.length > 0) {
                        /**
                         * Filter out existing existing people that are in the roster. We don't want dupes
                         *
                         * @type {Array}
                         */
                        success = _.filter(success, function (user) {
                            var existingUser = _.findWhere(offering.roster.attendingStudents, { id: user.id });

                            if (typeof existingUser !== "undefined" && existingUser !== null && user.id === existingUser.id) {
                                return false;
                            }
                            return user;
                        });

                        if (success.length === 0) {
                            offering.rosterUser = "";
                            offering.search.error.setError(404, 'There are no items to display.');
                            return false;
                        }
                    }
                    var finalSorted = sortService.sortByPropertyContains(success, value, 'fullName');
                    return finalSorted;
                }, function (failure) {
                    offering.search.isLoading = false;
                    offering.search.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            };

            /**
             * Insert the user into an offering's roster
             *
             * @param $item {obj} Selected item
             * @param $model {obj} Selected item
             * @param $label {string} Label that contains selected info
             * @param $event {obj} Click event
             * @param offering {obj} Offering to insert users into an roster
             */
            $scope.manageCourse.selectUsersForRoster = function ($item, $model, $label, $event, offering) {
                offering.rosterUser = '';
                if (offering.roster.attendingStudents.length < offering.maxAllowedSize) {
                    offering.roster.attendingStudents.push($item);
                    offering.isDirty = true;
                    offering.roster.isDirty = true;
                }
            };

            /**
             * Remove user from an offering's roster
             *
             * @param user {obj} User to be removed
             * @param offering {obj} Offering whose roster will be modified
             */
            $scope.manageCourse.removeUserFromRoster = function (user, offering) {
                var index = offering.roster.attendingStudents.indexOf(user);

                offering.roster.attendingStudents.splice(index, 1);

                var courseUpdateMethod = $route.current.params.name;
                if (typeof courseUpdateMethod !== 'undefined' && courseUpdateMethod === 'edit') {
                    user.isLoading = true;
                    var user_deleteFromRoster = RosterService.updateSinglePersonOnRoster(user.id, offering.id, 'cancelled', 'ilt');
                    user_deleteFromRoster.then(function (success) {
                        user.isLoading = false;

                    }, function (failure) {
                        user.isLoading = false;
                        user.error.setError(failure.ErrorCode, failure.ErrorMessage);
                    });
                }
            };

            // Only used on the details page
            $scope.manageCourse.addExtraCourseProperties = function (confirmed) {

                if ($scope.manageCourse.skillsUpdated === true && confirmed !== true) {
                    $scope.manageCourse.childrenUpdated = false;
                    $scope.manageCourse.confirmIsOpen = true;
                    return;
                }
                else {
                    $scope.manageCourse.confirmIsOpen = false;
                }

                $scope.manageCourse.stepThree.isLoading = true;

                $scope.manageCourse.stepThree.error.reset();

                var courseToUpdate = angular.copy($scope.manageCourse.course);
                if (courseToUpdate.type === 'careerPath') {
                    courseToUpdate.childCourses = _this.getOrderedChildCourses(courseToUpdate.childCourses);
                }

                if (courseToUpdate.productType === 'Document') {
                    //Stub area for EA-8842 to set course type
                    //Until upload is finalized, add success message
                    $scope.manageCourse.stepThree.isLoading = false;
                    _this.performSecondaryAction();
                }

                var updatedCourse = CoursesService.updateCourse(courseToUpdate);
                updatedCourse.then(function () {
                    var documentList = (typeof $scope.manageCourse.course.documentList !== 'undefined') ? $scope.manageCourse.course.documentList : $scope.manageCourse.documentList;
                    var filesToUpload = _this._attachFiles($scope.manageCourse.course.id, $scope.manageCourse.scormPackages, documentList, $scope.manageCourse.course.shellType);

                    filesToUpload.then(function () {
                        $scope.manageCourse.stepThree.isLoading = false;
                        $rootScope.$broadcast("RefreshDocList", { courseId: $scope.manageCourse.course.id });
                        _this.performSecondaryAction();
                    }, function (error) {
                        $rootScope.$broadcast("RefreshDocList", { courseId: $scope.manageCourse.course.id });
                        $scope.manageCourse.stepThree.isLoading = false;
                        $scope.manageCourse.stepThree.error.setError(error.ErrorCode, error.ErrorMessage);
                    });

                }, function (error) {
                    $scope.manageCourse.stepThree.isLoading = false;

                    $scope.manageCourse.stepThree.error.setError(error.ErrorCode, error.ErrorMessage);
                });
            };

            //Launch Latitude's assessment editor for surveys/evaluations during the creation process
            $scope.launchEditor = function (url) {


                //sly-3910/3911 need to update so a script can run
                var courseToUpdate = angular.copy($scope.manageCourse.course);
                var updatedCourse = CoursesService.updateCourse(courseToUpdate);
                updatedCourse.then(function () {
                    console.log("eval success");
                    $scope.launchUrl(url, '_blank');
                }
                );
            };




            _this.filterRowsByCatalogId = function (node) {
                var nodeId = node.nodeId;
                _.each($scope.coursesSelected, function (course) {
                    if (course.catalogId === nodeId) {
                        _this.emptyArray.push(course);
                    }
                });

                if (node.nodes) {
                    _.each(node.nodes, function (childNode) {
                        _this.filterRowsByCatalogId(childNode);
                    });
                }

                return _this.emptyArray;
            };

            /**
             * Method generalizes the course saving method. This way, we can have alternate paths, in case we need
             * to do something else other than attaching files/creating sessions/etc.
             *
             * @void
             */
            _this.performSecondaryAction = function (edit) {
                switch ($scope.manageCourse.course.type) {
                    case 'ilt':
                        if (edit === true) {

                        } else {
                            for (var i = 0; i < $scope.manageCourse.course.offerings.length; i++) {
                                $scope.manageCourse.course.offerings[i].courseId = $scope.manageCourse.course.id;
                            }
                        }

                        // Get locations for the next screen.
                        var locations = LocationsService.getAllActive();
                        locations.then(function (success) {
                            $scope.manageCourse.locations = success;

                        }, function () {
                            // @TODO: what do we do if there are no locations?
                        });

                        var instructors = UsersService.getAllInstructors();
                        instructors.then(function (success) {
                            $scope.manageCourse.stepFour.instructors = success;
                        }, function (failure) {

                        });

                        WizardHandler.wizard('Content').next();
                        $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(4);
                        break;
                    default:
                        _this._showWizardSuccessScreen();

                        break;
                }
            };

            $scope.changeSortBy = function (sortBy) {
                $scope.propertyName = sortBy;

                $scope.reverse = ($scope.propertyName === sortBy) ? !$scope.reverse : false;

                if (sortBy.indexOf('displayStart') >= 0) {
                    _this.currentCourseSet = _.sortBy(_this.currentCourseSet, function (c) {
                        var dt = new Date(c.displayStart.apiDate);
                        return dt;
                    });
                } else if (sortBy.indexOf('displayEnd') >= 0) {
                    _this.currentCourseSet = _.sortBy(_this.currentCourseSet, function (c) {
                        if (c.displayEnd !== 'undefined' && c.displayEnd !== null) {
                            var dt = new Date(c.displayEnd.apiDate);
                            return dt;
                        }
                        return -1;
                    });
                } else {
                    _this.currentCourseSet = _.sortBy(_this.currentCourseSet, $scope.propertyName);
                }


                if ($scope.reverse === true) {
                    _this.currentCourseSet.reverse();
                }

                /**
                 * Reset page size and info for paginator
                 */
                _this.calculatePages(_this.currentCourseSet);

                var start = ($scope.home.page * $scope.home.limit) - $scope.home.limit;

                var copy = angular.copy(_this.currentCourseSet);

                $scope.rows = copy.splice(start, $scope.home.limit);
            };

            $scope.selectedCourseCount = 0;
            $scope.checkForLength = function (value) {
                if (value === true) {
                    $scope.selectedCourseCount++;
                } else {
                    $scope.selectedCourseCount--;
                }
            };

            $scope.addAllCourses = function (value) {

                _.each($scope.courseData, function (course) {
                    course.select = value;
                });

                if (value === true) {
                    $scope.selectedCourseCount = $scope.courseData.length;
                } else {
                    $scope.selectedCourseCount = 0;
                }
            };

            _this.allCourses = function () {
                $scope.assignmentTableError.error.reset();
                var latestmatch = CoursesService.checkLatestCourse();
                latestmatch.then(function (success) {
                    var allCoursesData = CoursesService.getActiveCoursesForOrg(!success, true);

                    return allCoursesData.then(function (success) {
                        $scope.rowsAreLoading = false;
                        _this.createCourseArrays(success);

                        if (typeof $scope.edit === 'undefined') {
                            $scope.edit = {};
                        }
                        /*  Compose a list of evaluations from complete list of courses available.
                            needed to populate evaluation dropdown.     
                        */
                        if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "edit") {
                            //only need to do this if allCourses is called on Edit course 
                            $scope.manageCourse.evaluationsNsurveys = _this.getEvaluations(_this.allCoursesData);
                        }
                    }, function (failure) {
                        $scope.baiCatalogIsLoading = false;
                        $scope.rowsAreLoading = false;
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.assignmentTableError.error.setError(errorCode, message);
                    });
                });
            };

            _this.formatCoursesData = function (data) {
                var filteredData = [];
                _.each(data, function (datapoint) {
                    datapoint.select = false;
                    if (datapoint.type !== 'courseGroup' &&
                        datapoint.type !== 'courseGroupCertification' &&
                        datapoint.type !== 'courseGroupCurricula' &&
                        datapoint.type !== 'careerPath') {
                        filteredData.push(datapoint);
                    }
                });

                return filteredData;
            };


            _this.createCourseArrays = function (courses) {

                /**
                     * Keep data in memory and away from angular
                */
                _this.allCoursesData = courses.sortedList;

                _this.currentCourseSet = courses.currentCourseSet;

                /* set the bai courses / bai documents / custom courses array to use for catalog filtering */
                _this.customCourses = courses.customCourses;
                _this.baiDocuments = _this.currentCourseSet.filter(function (c) { return c.baiOnly && c.normalizedProductType === "document"; });
                _this.baiCourses = _this.currentCourseSet.filter(function (c) { return c.baiOnly && c.normalizedProductType !== "document"; });
                _this.customDocuments = _this.currentCourseSet.filter(function (c) { return !c.baiOnly && c.normalizedProductType === "document"; });

                /**
                 * Reset page size and info for paginator
                 */
                _this.calculatePages(_this.allCoursesData);

                /**
                 * Get the top 100 courses to render on the screen
                 */
                $scope.rows = courses.topOneHundred;

            };

            // do not filter out course groups, curricula or certifications
            _this.formatCoursesPrerequisiteData = function (data) {
                var filteredData = [];
                _.each(data, function (datapoint) {
                    datapoint.select = false;
                    if (datapoint.type !== 'courseGroup' &&
                        datapoint.type !== 'courseGroupCertification' &&
                        datapoint.type !== 'courseGroupCurricula' &&
                        datapoint.type !== 'careerPath' &&
                        datapoint.type !== 'task' &&
                        datapoint.type !== 'assessment' &&
                        datapoint.type !== 'evaluation' &&
                        datapoint.type !== 'survey' &&
                        datapoint.type !== 'vle') {
                        filteredData.push(datapoint);
                    }
                });

                return filteredData;
            };

            _this.getEvaluations = function (data) {
                /* Compose a list of evaluations from complete list of courses available.
                   needed to populate evaluation dropdown.     
                */
                var output = [];
                var tempCourse = {
                    name: 'Select One',
                    type: 'default',
                    evaluationId: 0,
                    id: 0
                };
                $filter('filter')(data, function (course) {
                    if (course.type === 'evaluation' || course.type === 'survey')
                        output.push(course);
                });
                var result = _.sortBy(output, 'name');
                result.unshift(tempCourse);

                return result;
            };
            _this.setEvaluations = function () {
                var allCoursesFromCache = CoursesService.getActiveCoursesForOrgFromCache();
                if (allCoursesFromCache !== undefined && allCoursesFromCache !== null) {
                    $scope.manageCourse.evaluationsNsurveys = _this.getEvaluations(allCoursesFromCache);
                }
                else {
                    _this.allCourses();
                }
            };

            $scope.courseSearchModal = {};
            $scope.courseSearchModal.courseSearch = '';
            $scope.courseSearchModal.selectAll = false;
            $scope.courseSearchModal.coursesAreLoading = true;

            $scope.viewCoursesError = {};
            $scope.viewCoursesError.error = messagingDisplayerUtil.create();

            $scope.getAllCoursesCta = function (keyword) {
                //changes start here
                $scope.modalBodyShow = true;
                $scope.selectedCourseCount = 0;
                $scope.viewCoursesError.error.reset();
                $scope.courseSearchModal.coursesAreLoading = true;
                $scope.courseSearchModal.selectAll = false;

                var allCoursesData = CoursesService.getActiveCoursesForOrg(false, false);
                allCoursesData.then(function (success) {
                    var formattedCourseData = _this.formatCoursesData(success);

                    if (typeof keyword !== 'undefined' && keyword !== null) {

                        formattedCourseData = $filter('filter')(formattedCourseData, keyword);
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });

                    } else {
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });
                    }

                    $scope.courseSearchModal.coursesAreLoading = false;

                }, function (error) {
                    $scope.courseSearchModal.coursesAreLoading = false;
                    var errorCode = error.StatusCode;
                    var message = error.ErrorMessage;
                    $scope.viewCoursesError.error.setError(errorCode, message);
                });
            };

            /* course search for modal to add courses to a Career Path */
            $scope.getAllCoursesWithSkills = function (keyword) {
                $scope.modalBodyShow = true;
                $scope.selectedCourseCount = 0;
                $scope.viewCoursesError.error.reset();
                $scope.courseSearchModal.coursesAreLoading = true;
                $scope.courseSearchModal.selectAll = false;

                var allCoursesData = CoursesService.getActiveCoursesForOrg(false, false);
                allCoursesData.then(function (success) {
                    var formattedCourseData = _this.formatCoursesData(success);
                    var courseIDs = [];

                    _.each(formattedCourseData, function (course) {
                        courseIDs.push(course.id);
                    });

                    var courseSkills = SkillService.fetchSkillsForCourses(courseIDs);
                    courseSkills.then(function (success) {
                        _.each(formattedCourseData, function (course) {
                            course.skills = _.where(success, { courseID: course.id });
                            course.skillsString = _this.skillsToString(course.skills);
                        });

                        if (typeof keyword !== 'undefined' && keyword !== null) {

                            formattedCourseData = $filter('filter')(formattedCourseData, keyword);
                            $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });

                        } else {
                            $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });

                        }

                        $scope.courseSearchModal.coursesAreLoading = false;

                    }, function (error) {
                        $scope.courseSearchModal.coursesAreLoading = false;
                        var errorCode = error.StatusCode;
                        var message = error.ErrorMessage;
                        $scope.viewCoursesError.error.setError(errorCode, message);
                    });



                }, function (error) {
                    $scope.courseSearchModal.coursesAreLoading = false;
                    var errorCode = error.StatusCode;
                    var message = error.ErrorMessage;
                    $scope.viewCoursesError.error.setError(errorCode, message);
                });
            };

            _this.skillsToString = function (skills) {
                var sorted = _.sortBy(skills, 'name');
                var skillsString = '';
                var i;
                for (i = 0; i < skills.length; i++) {
                    if (i !== 0) {
                        skillsString += ', ';
                    }
                    skillsString += sorted[i].name;
                }
                return skillsString;
            };

            $scope.getAllCoursesPrerequisiteCta = function (keyword) {
                $scope.courseactivetab = false;
                $scope.modalBodyShow = true;
                $scope.selectedCourseCount = 0;
                $scope.viewCoursesError.error.reset();
                $scope.courseSearchModal.coursesAreLoading = true;
                $scope.courseSearchModal.selectAll = false;

                var allCoursesData = CoursesService.getActiveCoursesForOrg(false, false);
                allCoursesData.then(function (success) {
                    var formattedCourseData = _this.formatCoursesPrerequisiteData(success);

                    if (typeof keyword !== 'undefined' && keyword !== null) {

                        formattedCourseData = $filter('filter')(formattedCourseData, keyword);
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });

                    } else {
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });
                    }

                    $scope.courseSearchModal.coursesAreLoading = false;

                }, function (error) {
                    $scope.courseSearchModal.coursesAreLoading = false;
                    var errorCode = error.StatusCode;
                    var message = error.ErrorMessage;
                    $scope.viewCoursesError.error.setError(errorCode, message);
                });
            };

            $scope.applyNewAssignmentsCta = function (data) {
                var selectedCourses = _this.getSelectedCourses(data);
                var selectedCourseIds = [];
                _.each(selectedCourses, function (course) {
                    if (typeof ($scope.manageCourse.course.childCourses) !== 'undefined' && $scope.manageCourse.course.childCourses.indexOf(course) === -1) {
                        $scope.manageCourse.course.childCourses.push(course);
                    }
                    if (typeof ($scope.manageCourse.previouslyAddedCourses) !== 'undefined' && $scope.manageCourse.previouslyAddedCourses.indexOf(course) === -1) {
                        $scope.manageCourse.previouslyAddedCourses.push(course);
                    }
                });
                $scope.manageCourse.course.childCourses = _this.uniqueCourses($scope.manageCourse.course.childCourses);
            };

            $scope.applyNewPrerequisiteCta = function (data) {
                var selectedCourses = _this.getSelectedCourses(data);

                _.each(selectedCourses, function (course) {
                    if ($scope.manageCourse.course.prereq === null) {
                        $scope.manageCourse.course.prereq = [];
                    }

                    var existingIdx = _.findIndex($scope.coursePrerequisitsOnLoad, { "id": course.id });
                    var removedIdx = $scope.prereqsToRemove.indexOf(course.id);
                    var addIdx = $scope.prereqsToInsert.indexOf(course.id);
                    //If the added prereq exists in the removed and the original prereq list - remove it from the removed array because it is being re-added
                    if (existingIdx > -1 && removedIdx > -1) {
                        $scope.prereqsToRemove.splice(removedIdx, 1);
                    } else if (addIdx === -1 && existingIdx === -1) {
                        $scope.prereqsToInsert.push(course.id);
                    }
                    $scope.manageCourse.course.prereq.push(course);

                });

                $scope.manageCourse.course.prereq = _this.uniqueCourses($scope.manageCourse.course.prereq);
            };


            _this.getSelectedCourses = function (data) {
                var selectedCoursesArray = [];

                _.each(data, function (datapoint) {
                    if (datapoint.select === true) {
                        selectedCoursesArray.push(datapoint);
                    }
                });

                return selectedCoursesArray;
            };

            _this.uniqueCourses = function (data) {
                var output = [],
                    keys = [];

                _.each(data, function (datapoint) {
                    var key = datapoint.course.id;
                    if (keys.indexOf(key) === -1) {
                        keys.push(key);
                        output.push(datapoint);
                    }
                });

                return output;
            };

            $scope.removePrereqCourse = function (course) {

                //if the prereq is in the original list of prereqs, add it to the list of prereqs to remove
                var existingIdx = _.findIndex($scope.coursePrerequisitsOnLoad, { "id": course.id });
                var removedIdx = $scope.prereqsToRemove.indexOf(course.id);
                var addIdx = $scope.prereqsToInsert.indexOf(course.id);
                if (existingIdx > -1 && removedIdx === -1) {
                    $scope.prereqsToRemove.push(course.id);
                } else if (addIdx > -1) {
                    //If the removed prereq is in the insert list, remove it from the insert list
                    $scope.prereqsToInsert.splice(addIdx, 1);
                }
                $scope.manageCourse.course.prereq.splice($scope.manageCourse.course.prereq.indexOf(course), 1);
            };

            $scope.removeCourse = function (course) {
                $scope.manageCourse.course.childCourses.splice($scope.manageCourse.course.childCourses.indexOf(course), 1);
            };


            /***** Add New Courses Modal *****/
            $scope.addNewAssignmentCta = function () {
                $scope.courseSearchModal.courseSearch = '';
                $scope.selectedCourseCount = 0;
                $scope.courseSearchModal.selectAll = false;
                _this.formatCoursesData($scope.courseData);
                angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                angular.element(document.querySelector('#addNewAssignmentModal')).modal(); 
            };

            $scope.addNewPrerequisiteCta = function () {
                $scope.courseSearchModal.courseSearch = '';
                $scope.selectedCourseCount = 0;
                $scope.courseSearchModal.selectAll = false;
                //_this.formatCoursesData($scope.courseData);
                angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                angular.element(document.querySelector('#addNewPrerequisiteModal')).modal();
            };

            $scope.customCatalogError = {};
            $scope.customCatalogOptions = {};
            $scope.customCatalogError.error = messagingDisplayerUtil.create();
            $scope.customCatalogTreeTitle = "Custom Content";
            $scope.customCatalogOptions.showLocks = false;
            $scope.customCatalogOptions.initiallyOpened = true;
            $scope.customCatalogOptions.nodeClick = function (node) {
                if (node.api_name === 'courseGroups' && typeof node.id !== 'undefined' & typeof node.id !== null) {
                    $location.path('courses/edit/' + node.id + '/');
                }
            };

            _this.uniqueCourses = function (data) {
                var output = [],
                    keys = [];

                _.each(data, function (datapoint) {
                    var key = datapoint.id;
                    if (keys.indexOf(key) === -1) {
                        keys.push(key);
                        output.push(datapoint);
                    }
                });

                /**
                 * Reset page size and info for paginator
                 */
                _this.calculatePages(output);

                _this.currentCourseSet = angular.copy(output);

                if (output.length > $scope.home.limit) {
                    output = output.splice(0, $scope.home.pageSize);
                }

                return output;
            };

            _this.courseGroupSubset = function (item) {
                var output_Arr = [];
                if (item !== 'undefined' && item !== null) {
                    _.each(item.nodes, function (node) {
                        var courses = _this.coursesSubset(node);

                        _.each(courses, function (course) {
                            output_Arr.push(course);
                        });
                    });
                }

                return output_Arr;
            };

            _this.allCustomCourses = function (data) {
                var output_Arr = [],
                    courses;

                _.each(data, function (datapoint) {
                    if (datapoint.hasOwnProperty('courses')) {
                        courses = _this.coursesSubset(datapoint);
                        _.each(courses, function (course) {
                            output_Arr.push(course);
                        });
                    }

                    if (datapoint.hasOwnProperty('nodes')) {
                        courses = _this.courseGroupSubset(datapoint);
                        _.each(courses, function (course) {
                            output_Arr.push(course);
                        });
                    }
                });

                return output_Arr;
            };

            _this.coursesSubset = function (item) {
                var courses = item.courses;
                if (typeof courses !== 'undefined' && typeof courses !== null) {
                    var courseIds = _.pluck(courses, 'id');
                    var courseDataOriginal = _this.allCoursesData;
                    var output_Arr = [];

                    _.each(courseDataOriginal, function (course) {
                        _.each(courseIds, function (courseId) {
                            if (course.id === courseId) {
                                output_Arr.push(course);
                            }
                        });
                    });

                    return output_Arr;
                }
            };

            /**
             * Begin IModify functions
             */
            $scope.launchIModify = function (courseId) {
                var IModifyLinkCall = CoursesService.getImodifyUrl(courseId);
                IModifyLinkCall.then(
                    function (success) {
                        var link = success;
                        var popup = $window.open("about:blank", "_blank");
                        popup.location = link;
                    },
                    function (failure) {
                        //silent fail            
                    }
                );
            };

            $scope.resetIModifyCourseContent = function (courseId) {
                $scope.manageCourse.course.iModifyLoading = true;
                var IModifyResetCall = CoursesService.resetIModifyCourseContent(courseId);
                IModifyResetCall.then(
                    function (success) {
                        $scope.manageCourse.course.iModifyLoading = false;
                        $scope.IModifyMessage = "BAI IModify content was successfully reset";
                        $scope.IModifySuccessThrow = true;
                    },
                    function (failure) {
                        $scope.manageCourse.course.iModifyLoading = false;
                        $scope.IModifyMessage = failure.ErrorMessage;
                        $scope.IModifyErrorThrow = true;
                    }
                );
            };

            $scope.CreateNewIModifyCourse = function (courseId) {
                $scope.manageCourse.course.iModifyLoading = true;
                var IModifyLinkCall = CoursesService.CreateIModifyCourse(courseId);
                IModifyLinkCall.then(
                    function (success) {
                        $scope.manageCourse.course.iModifyLoading = false;
                        var courseId = success.id;
                        //Redirect to edit the new IModify course
                        $scope.IModifyRedirect(courseId);
                    },
                    function (failure) {
                        $scope.manageCourse.course.iModifyLoading = false;
                        $scope.IModifyMessage = failure.ErrorMessage;
                        $scope.IModifyErrorThrow = true;
                    }
                );
            };

            $scope.IModifyRedirect = function (courseId) {
                return $location.url('courses/edit/' + courseId);
            };


            /**
             * End IModify functions
             */

            $scope.launchIdesign = function () {
                var IdesignLinkCall = UsersService.getIdesignUrl();
                IdesignLinkCall.then(
                    function (success) {
                        var link = success;
                        var popup = $window.open("about:blank", "_blank");
                        popup.location = link;
                    },
                    function (failure) {
                        //silent fail            
                    }
                );
            };

            /**
             * Uncomment these lines to turn on tree
             */
            _this.baiCatalogCta();

            $scope.treeOptions = {
                nodeChildren: 'nodes',
                templateUrl: 'tree.html',
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            };

            _this.calculatePages = function (courses) {
                $scope.home.page = 1;

                /**
                 * Total number of elements
                 */
                $scope.home.total = courses.length;

                $scope.home.pageSize = $scope.home.page * $scope.home.limit;
                _this.currentCourseSet = courses;
            };

            _this.setStartandEndIndex = function () {
                $scope.startIndex = ($scope.home.page * $scope.home.limit) - $scope.home.limit;
                $scope.endIndex = $scope.startIndex + $scope.home.limit;
            };

            $timeout(function () {
                var paginationEl = $('#pagination');
                paginationEl.find('a').addClass("portalBackground");
            }, 3000);

            _this.setSelectedOffering = function () {
                if (typeof $scope.manageCourse.stepFive.sessionCreator.offering === 'undefined' || $scope.manageCourse.stepFive.sessionCreator.offering === null) {
                    var mostrecentOffering = _.findWhere($scope.manageCourse.course.offerings, { "status": $scope.manageCourse.showOfferingsWithStatus });
                    //findWhere retruns undefined if no match is found, or if list is empty.
                    $scope.manageCourse.stepFive.sessionCreator.offering = typeof mostrecentOffering !== "undefined" ? mostrecentOffering.localId : null;
                }

            };

            // Right now this is to setup step 5 for ILTs. I think that's currently the only course type with a step 5 so not bothering to include other course types here but they could be with a switch statement.
            $scope.initStep5 = function () {

                $scope.manageCourse.onSessionsTab = true;
                $scope.manageCourse.onOfferingsTab = false;
                $scope.manageCourse.onRosterTab = false;

                _this.setSelectedOffering();

                _this.setIltSessionMinMaxDates();

                if (WizardHandler && WizardHandler.wizard('Content')) {
                    WizardHandler.wizard('Content').next();
                    $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(5);
                }

                var dateObj = new Date().toString();
                $scope.localTimezoneString = dateObj.substring(dateObj.indexOf('(') + 1, dateObj.indexOf(')'));
                jQuery('.taModal').modal('hide');
            };

            $scope.step5OfferingSelectChanged = function () {
                _this.setIltSessionMinMaxDates();
            };

            this.validateSessionCreatorDates = function (selectedOffering) {

                var minDateToCheck = null;
                _.each([(typeof selectedOffering !== "undefined" && selectedOffering !== null) ? selectedOffering.registrationEndDateTime : null, $scope.manageCourse.course.displayStart], function (dt) {
                    if (typeof dt !== "undefined" && dt !== null && _.isObject(dt.moment)) {
                        if (minDateToCheck === null) {
                            minDateToCheck = dt.moment;
                        } else if (minDateToCheck.isAfter(dt.moment)) {
                            minDateToCheck = dt.moment;
                        }
                    }
                });

                var maxDateToCheck = null;
                if (typeof $scope.manageCourse.course.displayEnd !== "undefined" && $scope.manageCourse.course.displayEnd !== null && _.isObject($scope.manageCourse.course.displayEnd.moment)) {
                    maxDateToCheck = $scope.manageCourse.course.displayEnd.moment;
                }

                //StartDate
                if ($scope.manageCourse.stepFive.sessionCreator.startDate !== null && _.isObject($scope.manageCourse.stepFive.sessionCreator.startDate.moment)) {
                    if ($scope.manageCourse.stepFive.sessionCreator.startDate.moment.isBefore(minDateToCheck)) {
                        $scope.manageCourse.stepFive.sessionCreator.startDate = null;
                    }

                    if ($scope.manageCourse.stepFive.sessionCreator.startDate.moment.isAfter(maxDateToCheck)) {
                        $scope.manageCourse.stepFive.sessionCreator.startDate = null;
                    }
                }

                //EndDate
                if ($scope.manageCourse.stepFive.sessionCreator.endDate !== null && _.isObject($scope.manageCourse.stepFive.sessionCreator.endDate.moment)) {
                    if ($scope.manageCourse.stepFive.sessionCreator.endDate.moment.isBefore(minDateToCheck)) {
                        $scope.manageCourse.stepFive.sessionCreator.endDate = null;
                    }

                    if ($scope.manageCourse.stepFive.sessionCreator.endDate.moment.isAfter(maxDateToCheck)) {
                        $scope.manageCourse.stepFive.sessionCreator.endDate = null;
                    }
                }
            };

            _this.setIltSessionMinMaxDates = function () {
                var currentOfferingIndex = $scope.manageCourse.stepFive.sessionCreator.offering;

                var currentOffering = null;
                if (currentOfferingIndex) {
                    currentOffering = _.find($scope.manageCourse.course.offerings, function (offering) {
                        return parseInt(offering.localId, 10) === parseInt($scope.manageCourse.stepFive.sessionCreator.offering, 10);
                    });
                }

                _this.validateSessionCreatorDates(currentOffering);

                if (typeof currentOffering !== "undefined" && currentOffering !== null) {
                    var registrationEndDateTime = currentOffering.registrationEndDateTime;
                    if (registrationEndDateTime) {
                        if (registrationEndDateTime.moment) {
                            $scope.ilt.minSessionDate = registrationEndDateTime.moment;
                            if ($scope.manageCourse.course.displayEnd) {
                                if ($scope.manageCourse.course.displayEnd.moment) {
                                    $scope.ilt.maxSessionDate = $scope.manageCourse.course.displayEnd.moment;
                                } else {
                                    $scope.ilt.maxSessionDate = null;
                                }
                            } else {
                                $scope.ilt.maxSessionDate = null;
                            }
                        } else {
                            $scope.ilt.minSessionDate = $scope.manageCourse.course.displayStart.moment;

                            if ($scope.manageCourse.course.displayEnd) {
                                if ($scope.manageCourse.course.displayEnd.moment) {
                                    $scope.ilt.maxSessionDate = $scope.manageCourse.course.displayEnd.moment;
                                } else {
                                    $scope.ilt.maxSessionDate = null;
                                }
                            } else {
                                $scope.ilt.maxSessionDate = null;
                            }
                        }
                    } else {
                        if (currentOffering.requestedOn && currentOffering.requestedOn.moment) {
                            $scope.ilt.minSessionDate = currentOffering.requestedOn.moment;
                        } else {
                            $scope.ilt.minSessionDate = $scope.manageCourse.course.displayStart.moment;
                        }


                        if ($scope.manageCourse.course.displayEnd) {
                            if ($scope.manageCourse.course.displayEnd.moment) {
                                $scope.ilt.maxSessionDate = $scope.manageCourse.course.displayEnd.moment;
                            } else {
                                $scope.ilt.maxSessionDate = null;
                            }
                        } else {
                            $scope.ilt.maxSessionDate = null;
                        }
                    }
                } // end if there is a current offering selected
                else {
                    $scope.ilt.minSessionDate = $scope.manageCourse.course.displayStart.moment;
                    if ($scope.manageCourse.course.displayEnd) {
                        if ($scope.manageCourse.course.displayEnd.moment) {
                            $scope.ilt.maxSessionDate = $scope.manageCourse.course.displayEnd.moment;
                        } else {
                            $scope.ilt.maxSessionDate = null;
                        }
                    } else {
                        $scope.ilt.maxSessionDate = null;
                    }
                } // end there is no current offering selected
            };

            _this.SetCourseStartDateBoundaries = function () {
                var maxCourseStartDate = null;

                if ($scope.manageCourse.course.offerings) {
                    for (var i = 0; i < $scope.manageCourse.course.offerings.length; i++) {
                        var offering = $scope.manageCourse.course.offerings[i];

                        if (offering.requestedOn) {
                            if (offering.requestedOn.jsDate) {
                                if (maxCourseStartDate === null) {
                                    maxCourseStartDate = offering.requestedOn.jsDate;
                                } else if (maxCourseStartDate > offering.requestedOn.jsDate) {
                                    maxCourseStartDate = offering.requestedOn.jsDate;
                                }
                            }
                        }

                        if (offering.registrationEndDateTime) {
                            if (offering.registrationEndDateTime.jsDate) {
                                if (maxCourseStartDate === null) {
                                    maxCourseStartDate = offering.registrationEndDateTime.jsDate;
                                } else if (maxCourseStartDate > offering.registrationEndDateTime.jsDate) {
                                    maxCourseStartDate = offering.registrationEndDateTime.jsDate;
                                }
                            }
                        }
                        if (offering.sessions) {
                            for (var j = 0; j < offering.sessions.length; j++) {
                                var session = offering.sessions[j];
                                if (maxCourseStartDate === null) {
                                    maxCourseStartDate = session.endDateTime.jsDate;
                                } else if (maxCourseStartDate > session.endDateTime.jsDate) {
                                    maxCourseStartDate = session.endDateTime.jsDate;
                                }
                            }
                        }
                    }
                }

                if (maxCourseStartDate === null) {
                    if (typeof $scope.manageCourse.course.displayEnd !== "undefined" && $scope.manageCourse.course.displayEnd !== null && typeof $scope.manageCourse.course.displayEnd.moment !== "undefined" && _.isObject($scope.manageCourse.course.displayEnd.moment)) {
                        $scope.manageCourse.course.maxCourseStartDate = $scope.manageCourse.course.displayEnd;
                    } else {
                        $scope.manageCourse.course.maxCourseStartDate = null;
                    }
                } else {
                    $scope.manageCourse.course.maxCourseStartDate = dateUtil.formatDate(maxCourseStartDate);
                }
            };

            _this.SetCourseEndDateBoundaries = function () {
                var minCourseEndDate = null;

                if ($scope.manageCourse.course.offerings) {
                    for (var i = 0; i < $scope.manageCourse.course.offerings.length; i++) {
                        var offering = $scope.manageCourse.course.offerings[i];

                        if (offering.requestedOn) {
                            if (offering.requestedOn.jsDate) {
                                if (minCourseEndDate === null) {
                                    minCourseEndDate = offering.requestedOn.jsDate;
                                } else if (minCourseEndDate < offering.requestedOn.jsDate) {
                                    minCourseEndDate = offering.requestedOn.jsDate;
                                }
                            }
                        }

                        if (offering.registrationEndDateTime) {
                            if (offering.registrationEndDateTime.jsDate) {
                                if (minCourseEndDate === null) {
                                    minCourseEndDate = offering.registrationEndDateTime.jsDate;
                                } else if (minCourseEndDate < offering.registrationEndDateTime.jsDate) {
                                    minCourseEndDate = offering.registrationEndDateTime.jsDate;
                                }
                            }
                        }
                        if (offering.sessions) {
                            for (var j = 0; j < offering.sessions.length; j++) {
                                var session = offering.sessions[j];
                                if (minCourseEndDate === null) {
                                    minCourseEndDate = session.endDateTime.jsDate;
                                } else if (minCourseEndDate < session.endDateTime.jsDate) {
                                    minCourseEndDate = session.endDateTime.jsDate;
                                }
                            }
                        }
                    }
                }

                if (minCourseEndDate === null) {
                    $scope.manageCourse.course.minCourseEndDate = $scope.manageCourse.course.displayStart;
                } else {
                    $scope.manageCourse.course.minCourseEndDate = dateUtil.formatDate(minCourseEndDate);
                }
            };

            $scope.effectiveStartDateChanged = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalStartTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                    $scope.manageCourse.course.displayStart = dateUtil.formatDate(dateObj.jsDate);
                } else {
                    $scope.manageCourse.course.displayStart = null;
                    dateObj = null;
                }
                return dateObj;
            };

            $scope.effectiveEndDateChanged = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalEndTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                    $scope.manageCourse.course.displayEnd = dateUtil.formatDate(dateObj.jsDate);
                } else {
                    $scope.manageCourse.course.displayEnd = null;
                    dateObj = null;
                }
                return dateObj;
            };

            // Right now this is to setup step 5 for ILTs. I think that's currently the only course type with a step 5 so not bothering to include other course types here but they could be with a switch statement.
            $scope.initStep4 = function () {
                $scope.manageCourse.onOfferingsTab = true;
                $scope.manageCourse.onRosterTab = false;


                _this.setEnrollmentDateBoundries();
                _this.setSelectedOfferingForOfferings();

                _this.setSelectedOffering();

                _this.setIltSessionMinMaxDates();

                var dateObj = new Date().toString();
                $scope.localTimezoneString = dateObj.substring(dateObj.indexOf('(') + 1, dateObj.indexOf(')'));
                jQuery('.taModal').modal('hide');
            };

            $scope.initStep6 = function () {
                $scope.manageCourse.onSessionsTab = false;
                $scope.manageCourse.onOfferingsTab = false;
                $scope.manageCourse.onRosterTab = true;

                if (WizardHandler && WizardHandler.wizard('Content')) {
                    WizardHandler.wizard('Content').next();
                    $scope.manageCourse.cancelMessage = _this.setCourseCancelMessage(5);
                }

                _this.setSelectedOffering();

                jQuery('.taModal').modal('hide');
            };

            _this.setSelectedOfferingForOfferings = function () {
                var numOfOfferingsToLoad = 20;

                if (typeof $scope.manageCourse.stepFive.sessionCreator.offering !== 'undefined' && $scope.manageCourse.stepFive.sessionCreator.offering !== null) {

                    var selectedOfferingIndex = _.findIndex($scope.manageCourse.course.offerings, function (offering) {
                        return parseInt(offering.localId, 10) === parseInt($scope.manageCourse.stepFive.sessionCreator.offering, 10);
                    });
                    if (selectedOfferingIndex !== -1) {
                        numOfOfferingsToLoad = selectedOfferingIndex > 19 ? selectedOfferingIndex + 1 : 20;
                        $scope.manageCourse.course.offerings[selectedOfferingIndex].accordionOpen = true;
                    }
                }
                $scope.displayedOfferings.length = 0;
                _.each(_.where($scope.manageCourse.course.offerings, { "status": $scope.manageCourse.showOfferingsWithStatus }).slice(0, numOfOfferingsToLoad), function (o) {
                    $scope.displayedOfferings.push(o);
                });
            };

            _this.setNewOfferingDefaults = function (offering) {
                // Set defaults
                // Defaulting name to something random enough and it looks official
                offering.name = 'OFR' + Math.floor(Math.random() * 1000000000).toString();
                offering.minimumEnrollment = 0;
                offering.maxAllowedSize = 39;
                offering.cancelDays = 0;
                offering.waitlistDays = 0;
                offering.maxWaitlistSize = 0;
                offering.status = 'open';
                offering.isDirty = true;
                //  var today = new Date();
                //  today.setHours(0, 0, 0, 0); // Don't care about time
                //  if (today >= $scope.manageCourse.course.displayStart.jsDate) {
                //      offering.requestedOn = dateUtil.formatDate(today);
                // } else {
                //     offering.requestedOn = $scope.manageCourse.course.displayStart;
                //}
            };

            _this.setOfferingAccordionHeading = function (offering) {
                offering.accordionHeading = "";
                if (offering.name) {
                    offering.accordionHeading += offering.name + " | ";
                }
                if (offering.location) {
                    offering.accordionHeading += offering.location + " | ";
                }
                if (offering.sessions) {
                    if (offering.sessions.length > 0) {
                        var earliestSessionDate = null;

                        earliestSessionDate = _.sortBy(offering.sessions, "startDateTime")[0].startDateTime;

                        if (earliestSessionDate !== null) {
                            offering.accordionHeading += earliestSessionDate.fullDate + " | ";
                        }
                    }
                }
                if (offering.status) {
                    offering.accordionHeading += offering.status + " | ";
                }
                if (offering.roster) {
                    if (offering.roster.attendingStudents) {
                        offering.accordionHeading += offering.roster.attendingStudents.length + " / ";
                    } else {
                        offering.accordingHeading += "0 / ";
                    }
                } else {
                    offering.accordingHeading += "0 / ";
                }
                if (offering.maxAllowedSize) {
                    offering.accordionHeading += offering.maxAllowedSize;
                }
            };

            _this.setEnrollmentDateBoundries = function () {
                _.each($scope.manageCourse.course.offerings, function (offering) {
                    _this.setOfferingEnrollmentOpensDateBoundries(offering);
                    _this.setOfferingEnrollmentEndDateBoundries(offering);
                });
            };

            $scope.enrollmentClosesChanged = function (currentOffering, dateObj) {
                if (typeof dateObj.moment === "undefined" || dateObj.moment === null || dateObj.moment === true) {
                    currentOffering.registrationEndDateTime = undefined;
                    return;
                }
                if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                    var prtEndTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                    currentOffering.registrationEndDateTime = dateUtil.formatDate(prtEndTime);
                    dateObj = dateUtil.formatDate(prtEndTime);
                }
                _this.setOfferingEnrollmentOpensDateBoundries(currentOffering);
                return dateObj;
            };

            $scope.enrollmentOpensChanged = function (currentOffering, dateObj) {
                if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                    var prtStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                    currentOffering.requestedOn = dateUtil.formatDate(prtStartTime);
                    dateObj = dateUtil.formatDate(prtStartTime);
                }
                _this.setOfferingEnrollmentEndDateBoundries(currentOffering);
                return dateObj;
            };

            _this.setOfferingEnrollmentOpensDateBoundries = function (currentOffering) {
                var i;
                var earliestSessionDate = null;
                if (currentOffering.registrationEndDateTime) {
                    if (currentOffering.registrationEndDateTime.moment) {
                        currentOffering.maxRequestedOnDate = currentOffering.registrationEndDateTime.moment;
                    } else {
                        if (currentOffering.sessions) {
                            earliestSessionDate = null;
                            for (i = 0; i < currentOffering.sessions.length; i++) {
                                if (earliestSessionDate === null) {
                                    earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                                }
                                if (earliestSessionDate > currentOffering.sessions[i].startDateTime.jsDate) {
                                    earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                                }

                            }

                            if (earliestSessionDate === null) {
                                if ($scope.manageCourse.course.displayEnd) {
                                    if ($scope.manageCourse.course.displayEnd.moment) {
                                        currentOffering.maxRequestedOnDate = $scope.manageCourse.course.displayEnd.moment;
                                    } else {
                                        currentOffering.maxRequestedOnDate = null;
                                    }
                                } else {
                                    currentOffering.maxRequestedOnDate = null;
                                }
                            } else {
                                currentOffering.maxRequestedOnDate = moment(earliestSessionDate);
                            }
                        } else {
                            if ($scope.manageCourse.course.displayEnd) {
                                if ($scope.manageCourse.course.displayEnd.moment) {
                                    currentOffering.maxRequestedOnDate = $scope.manageCourse.course.displayEnd.moment;
                                } else {
                                    currentOffering.maxRequestedOnDate = null;
                                }
                            } else // otherwise displayEnd doesn't exist
                            {
                                currentOffering.maxRequestedOnDate = null;
                            }
                        }
                    }
                } else {
                    // no registrationEndDateTime exists
                    if (currentOffering.sessions) {
                        earliestSessionDate = null;
                        for (i = 0; i < currentOffering.sessions.length; i++) {
                            if (earliestSessionDate === null) {
                                earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                            }
                            if (earliestSessionDate > currentOffering.sessions[i].startDateTime.jsDate) {
                                earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                            }

                        }

                        if (earliestSessionDate === null) {
                            if ($scope.manageCourse.course.displayEnd) {
                                if ($scope.manageCourse.course.displayEnd.moment) {
                                    currentOffering.maxRequestedOnDate = $scope.manageCourse.course.displayEnd.moment;
                                } else {
                                    currentOffering.maxRequestedOnDate = null;
                                }
                            } else {
                                currentOffering.maxRequestedOnDate = null;
                            }
                        } else {
                            currentOffering.maxRequestedOnDate = moment(earliestSessionDate);
                        }
                    } else {
                        if ($scope.manageCourse.course.displayEnd) {
                            if ($scope.manageCourse.course.displayEnd.moment) {
                                currentOffering.maxRequestedOnDate = $scope.manageCourse.course.displayEnd.moment;
                            } else {
                                currentOffering.maxRequestedOnDate = null;
                            }
                        } else // otherwise displayEnd doesn't exist
                        {
                            currentOffering.maxRequestedOnDate = null;
                        }
                    }
                } // end registrationEndDateTime doesn't exist
            };

            _this.setOfferingEnrollmentEndDateBoundries = function (currentOffering) {
                if (currentOffering.requestedOn) {
                    if (currentOffering.requestedOn.moment) {
                        currentOffering.minRegistrationEndDateTime = currentOffering.requestedOn.moment;
                    } else {
                        currentOffering.minRegistrationEndDateTime = $scope.manageCourse.course.displayStart.moment;
                    }
                } else {
                    currentOffering.minRegistrationEndDateTime = $scope.manageCourse.course.displayStart.moment;
                }

                if (currentOffering.sessions) {
                    var earliestSessionDate = null;
                    for (var i = 0; i < currentOffering.sessions.length; i++) {
                        if (earliestSessionDate === null) {
                            earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                        }
                        if (earliestSessionDate > currentOffering.sessions[i].startDateTime.jsDate) {
                            earliestSessionDate = currentOffering.sessions[i].startDateTime.jsDate;
                        }

                    }

                    if (earliestSessionDate === null) {
                        if ($scope.manageCourse.course.displayEnd) {
                            if ($scope.manageCourse.course.displayEnd.moment) {
                                currentOffering.maxRegistrationEndDateTime = $scope.manageCourse.course.displayEnd.moment;
                            } else {
                                currentOffering.maxRegistrationEndDateTime = null;
                            }
                        } else {
                            currentOffering.maxRegistrationEndDateTime = null;
                        }
                    } else {
                        currentOffering.maxRegistrationEndDateTime = moment(earliestSessionDate);
                    }
                } else {
                    if ($scope.manageCourse.course.displayEnd) {
                        if ($scope.manageCourse.course.displayEnd.moment) {
                            currentOffering.maxRegistrationEndDateTime = $scope.manageCourse.course.displayEnd.moment;
                        } else {
                            currentOffering.maxRegistrationEndDateTime = null;
                        }
                    } else {
                        currentOffering.maxRegistrationEndDateTime = null;
                    }
                }
            }; // end setOfferingEnrollmentEndDateBoundaries

            $scope.offeringNameChanged = function (offering) {
                // If there are sessions and the offering name changes, change all session names to match the new offering name.
                // TODO: The offeringName is passed on each session but the api only needs offering.name but we're also storing the name on each session.
                // There could be an easier way to figure out what offering we're on when adding sessions and use the offering.name property in the UI as well. Then we won't have to keep track of this below.
                if (typeof offering.sessions !== 'undefined' && offering.sessions !== null) {
                    _.each(offering.sessions, function (session) {
                        session.offeringName = offering.name;
                    });
                }

                _this.setOfferingAccordionHeading(offering);
            };

            $scope.maxAllowedSizeChanged = function (offering) {
                _this.setOfferingAccordionHeading(offering);
            };

            /**
            *
            * To get offering that has no session attached yet.
            */
            _this.getOfferingwithNoSession = function () {
                var offerings = $scope.manageCourse.course.offerings;
                var noSessionOffering = {};
                _.each(offerings, function (offering) {
                    if (!(offering.sessions && offering.sessions.length > 0)) {
                        noSessionOffering = offering;
                        return;
                    }
                });

                return noSessionOffering;
            };

            /**
             * SLY-3933
             * Update passing score if its is change and open the LAT
             * assessment engine in new tab.
             *
             * @param url {string} url for assessment engine.
             */
            _this.updatePassingScorelaunchUrl = function (url) {
                var course = $scope.manageCourse.course;

                //update course
                var courseToUpdate = angular.copy($scope.manageCourse.course);
                var updatedCourse = CoursesService.updateCourse(courseToUpdate);
                updatedCourse.then(function (success) {
                    window.open(url, '_blank');
                }, function (error) {
                    console.log('Error saving course: ' + error);
                });


            };

            $scope.manageCourse.displayOfferingsWithStatus = function () {

                $scope.manageCourse.showOfferingsLoading = true;
                $scope.manageCourse.editOfferingsIsLoading = true;

                $scope.manageCourse.stepFive.sessionCreator.offering = null;

                if ($scope.manageCourse.onSessionsTab) {

                    _this.setSelectedOffering();
                    _this.setIltSessionMinMaxDates();

                }

                if ($scope.manageCourse.onRosterTab) {

                    _this.setSelectedOffering();

                }

                if ($scope.manageCourse.onOfferingsTab) {
                    $scope.displayedOfferings.length = 0;
                    _.each(_.where($scope.manageCourse.course.offerings, { "status": $scope.manageCourse.showOfferingsWithStatus }).slice(0, 20), function (o) {
                        $scope.displayedOfferings.push(o);
                    });
                }


                $scope.manageCourse.showOfferingsLoading = false;
                $scope.manageCourse.editOfferingsIsLoading = false;
            };

            $scope.openInstructorDeskModal = function (offering) {

                var modalInstance = $uibModal.open({
                    templateUrl: 'app/templates/courses/create/modals/manageIltRoster.html',
                    controller: 'Offerings_AdminController',
                    //scope: $scope,
                    //size:'lg',
                    windowClass: 'rosterModal',
                    backdrop: 'static',
                    resolve: {
                        offeringId: function () {
                            return offering.id;
                        }
                    }
                });
                //LMS-7462-
                //modalInstance.result.then(
                //    //close
                //    function (result) {
                //        //on modal close - refresh the offering details

                //        $scope.manageCourse.editOfferingsIsLoading = true;
                //        $scope.manageCourse.showOfferingsLoading = true;
                //        var courseOfferingsPromise = CoursesService.getSingleCourseOfferings(offering.courseId);
                //        courseOfferingsPromise.then(function (success) {
                //            $scope.manageCourse.course.offerings = success; // offerings
                //            _this.setOfferingInstructors();
                //            _this.setSessions(success);

                //            _.each($scope.manageCourse.course.offerings, function (offering) {
                //                _this.setOfferingAccordionHeading(offering);
                //            });
                //            $scope.manageCourse.editOfferingsIsLoading = false;
                //            $scope.manageCourse.showOfferingsLoading = false;
                //            $scope.manageCourse.isLoading = false;
                //        }, function (failure) {
                //            $scope.manageCourse.editOfferingsIsLoading = false;
                //            $scope.manageCourse.showOfferingsLoading = false;
                //            $scope.manageCourse.isLoading = false;
                //            var errorCode = failure.StatusCode;
                //            var message = failure.ErrorMessage;
                //            $scope.manageCourse.error.setError(errorCode, message);
                //        });
                //    },
                //    //dismiss
                //    function (result) {
                //        var a = result;
                //    });
            };

            // SLY-5342 Sub domain management for courses admin tab
            $scope.isSubDomainAdmin = UsersService.isSubDomainAdmin();
            $scope.taDomains = UsersService.taDomains();
            $scope.activeDomain = UsersService.activeDomain();
            $scope.switchDomains = {};
            $scope.switchDomains.error = messagingDisplayerUtil.create();

            $scope.onActiveDomainChanged = function (activeDomain, oldDomain) {
                var switchProfilePromise = UsersService.switchProfile(activeDomain.id);
                switchProfilePromise.then(function (success) {
                    location.reload();
                }, function (failure) {
                    $scope.switchDomains.error.setError(failure.StatusCode, failure.ErrorMessage);
                    jQuery("#switchDomainsError").show();
                    setTimeout(function () {
                        $scope.switchDomains.error.reset();
                        jQuery("#switchDomainsError").hide();
                    }, 2000);
                });
            };

            $scope.hideFromCatalogChanged = function () {
                if ($scope.manageCourse.course.hideFromCatalog === true)
                    $scope.manageCourse.course.enrollmentClose = dateUtil.setStartTime(moment().utc());
                else
                    $scope.manageCourse.course.enrollmentClose = null;
            };

            $scope.ValidateCourseRetakeMonth = function (course) {
                course.enrollmentLimitRefreshMonth = dateUtil.validMonth(course.enrollmentLimitRefreshMonth);
                course.enrollmentLimitRefreshDate = dateUtil.validDate(course.enrollmentLimitRefreshMonth, course.enrollmentLimitRefreshDate);
            };

            $scope.ValidateCourseRetakeDate = function (course) {
                course.enrollmentLimitRefreshDate = dateUtil.validDate(course.enrollmentLimitRefreshMonth, course.enrollmentLimitRefreshDate);
            };

            $scope.setStartTime = function (dateObj) {
                if ((typeof dateObj !== "undefined" && dateObj !== null && typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment))) {
                    var startDatetime = dateObj.moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                    dateObj = dateUtil.formatDate(startDatetime);
                }
                return dateObj;
            };

            $scope.setEndTime = function (dateObj) {
                if ((typeof dateObj !== "undefined" && dateObj !== null && typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment))) {
                    var endDatetime = dateObj.moment.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
                    dateObj = dateUtil.formatDate(endDatetime);
                }
                return dateObj;
            };

            //career path
            $scope.selected = null;

            _this.getParentElement = function (targetNodeName, element) {
                if (targetNodeName.toLowerCase() === element.nodeName.toLowerCase()) {
                    return element;
                }
                return _this.getParentElement(targetNodeName, element.parentNode);
            };

            $scope.dragOver = function (e) {
                //IE11
                var target = e.target || e.srcElement;

                var tr = _this.getParentElement('tr', target);
                var tbody = _this.getParentElement('tbody', tr);
                if ($scope.isBefore($scope.selected, tr)) {
                    tbody.insertBefore($scope.selected, tr);
                }
                else {
                    tbody.insertBefore($scope.selected, tr.nextSibling);
                }

            };

            $scope.dragEnd = function () {
                $scope.selected = null;
                $scope.manageCourse.course.childCourses = _this.getOrderedChildCourses($scope.manageCourse.course.childCourses);
                $scope.manageCourse.childrenUpdated = true;

                $scope.$apply();
            };

            $scope.dragStart = function (e) {

                e.dataTransfer.effectAllowed = 'move';
                //attempt modern browser first, then fall back to IE11
                try {
                    e.dataTransfer.setData('text/html', null);
                }
                catch (ex) {
                    e.dataTransfer.setData('text', 'foo');
                }

                var selected = e.target || e.srcElement;
                $scope.selected = _this.getParentElement('tr', selected);

            };

            $scope.isBefore = function (el1, el2) {
                var cur;
                if (el2.parentNode === el1.parentNode) {
                    for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
                        if (cur === el2) return true;
                    }
                } else return false;
            };

            _this.getOrderedChildCourses = function (courses) {
                var orderedList = [];

                $('#orderedCourseList > tr').each(function () {
                    var curId = $(this).attr('id');
                    var course = _.find(courses, function (course) { return 'id-' + course.id === curId; });
                    orderedList.push(course);
                });

                return orderedList;
            };

            $scope.remove = function (array, index) {
                array.splice(index, 1);
                $scope.manageCourse.childrenUpdated = true;
            };

            /*
             * Course Skill search typeahead
             */
            $scope.noResults = '';
            $scope.skillsTypeahead = {};
            $scope.courseSkillsLimit = 5;
            $scope.skillsTypeahead.search = function (value) {
                if (value === null) {
                    /* validation - if the value is null, then just return after setting validation variable */
                    return;
                }

                var output = [];
                var filteredFromUsers = $filter('filter')($scope.availableSkills, function (rows) {
                    return ((rows.name.toLowerCase()).indexOf((value.toLowerCase()) || '') !== -1);
                });
                _.each(filteredFromUsers, function (item) {
                    var skinnedItem = {};
                    skinnedItem.id = item.id;
                    skinnedItem.name = item.name;
                    output.push(skinnedItem);
                });

                // If user typed value doesn't appear in type-ahead list, add it to the bottom as a new skill. All new skills have id = 0
                var existsInOutput = false;
                _.each(output, function (s) {
                    if (s.name.toLowerCase() === value.toLowerCase()) {
                        existsInOutput = true;
                    }
                });
                if (existsInOutput === false) {
                    output.push({ id: 0, name: value });
                }
                return output;
            };

            $scope.setCourseSkill = function (course, skill) {
                var skillIndex = -1;
                var courseSkillIndex = -1;

                $scope.manageCourse.skillsUpdated = true;

                if (course.skills === undefined || course.skills === null) {
                    course.skills = [];
                }

                courseSkillIndex = _.findIndex(course.skills, { "name": skill.name });
                if (courseSkillIndex === -1) {

                    var courseSkill = {};
                    courseSkill.canRemove = true;
                    courseSkill.name = skill.name;
                    courseSkill.skillID = skill.id;
                    courseSkill.courseID = course.id;

                    course.skills.push(courseSkill);
                }

                skillIndex = _.findIndex($scope.availableSkills, { "name": skill.name });
                if (skillIndex === -1) {
                    $scope.availableSkills.push(skill);
                }
            };

            $scope.removeCourseSkill = function (course, skill) {
                var skillIndex = -1;
                $scope.manageCourse.skillsUpdated = true;

                if (!skill.canRemove) {
                    return;
                }

                skillIndex = _.findIndex(course.skills, { "name": skill.name });
                if (skillIndex > -1) {
                    course.skills.splice(skillIndex, 1);
                }
            };

            $scope.imageList = [];
            $scope.imageSelect = function () {
                angular.element(document.querySelector('#imageSelectionModal')).modal();
                $scope.current = $scope.manageCourse.course.tileImage;
                $scope.shownImages = [];
                $scope.fetchImageList();
                //scroll to top

                //Clear selected from other choices
                var Images = jQuery('.tileImage');
                jQuery(Images).removeClass("selected");
                jQuery('.tileImage').first().addClass("selected");
                //load in proper images
                //order images
            };

            $scope.fetchImageList = function () {
                var temp = '';
                //Currently, default image based on type is mapped to images 1-20 here. 19 is currently used as the default 'This doesn't actually have a tileimage" image
                switch ($scope.manageCourse.course.type) {
                    case 'wbt':
                        temp = '0';
                        break;
                    case 'ilt':
                        temp = '1';
                        break;
                    case 'vle':
                        break;
                    case 'task':
                        temp = '2';
                        break;
                    case 'survey':
                        temp = '3';
                        break;
                    case 'evaluation':
                        temp = '4';
                        break;
                    case 'assessment':
                        temp = '5';
                        break;
                    case 'courseGroup':
                    case 'courseGroupCurricula':
                    case 'courseGroupCertification':
                        temp = '8';
                        break;
                    case 'careerPath':
                        temp = '9';
                        break;
                    case 'doc':
                    case 'pdf':
                    case 'url':
                    case 'mP3':
                    case 'mP4':
                    case 'word':
                    case 'other':
                        temp = '102';
                        break;
                    default:
                        break;
                }
                $scope.shownImages.push($scope.current);
                if ($scope.current.toString() !== ('grab_bag/' + temp)) { $scope.shownImages.push('grab_bag/' + temp); }
                if ($scope.manageCourse.course.productType === 'Document') {
                    $scope.tileImageSelectModalTitle = "Document Tile Images";

                    for (i = 71; i < 102; i++) {
                        if (i === 81) { //Number 81 already existed in the grab_bag, we skip that number.
                            continue;
                        }
                        temp = "grab_bag/" + i.toString();
                        if ($scope.current !== temp) $scope.shownImages.push(temp);
                    }
                }
                else {
                    $scope.tileImageSelectModalTitle = "Course Tile Images";
                    for (i = 21; i <= 162; i++) {
  
                        // Since we are adding new course images and we do not want to change the existing images 
                        // that customers may have selected for documents ( images 71 thru 102 ) we will skip those 
                        // and continue the loop
                        if (i >= 71 && i <= 102 ) {
                            continue;
                        }
                        temp = "grab_bag/" + i.toString();
                        if ($scope.current !== temp) $scope.shownImages.push(temp);
                    }
                }
            };
            $scope.current = '0';
            $scope.closeImageSelectionModal = function () {
                angular.element(document.querySelector('#imageSelectionModal')).modal('hide');
            };

            $scope.select = function ($event, selectedstring) {
                var target = $event.currentTarget;
                var Images = jQuery('.tileImage');
                jQuery(Images).removeClass("selected");
                jQuery(target).addClass("selected");
                $scope.current = selectedstring;
            };

            $scope.finalizeSelection = function () {
                //set larger scope variable to current
                angular.element(document.querySelector('#imageSelectionModal')).modal('hide');
                $scope.manageCourse.course.tileImage = $scope.current;
            };


            $scope.rebuildSubcategoryOptions = function (category) {

                $scope.manageCourse.course.catalogId = null;

                if (category === null) {
                    $scope.subcategoryDisabled = true;
                    $scope.subcategories = [];
                } else {
                    $scope.subcategoryDisabled = false;
                    $scope.subcategories = _.filter($scope.taxonomyOptions, { "categoryID": category.categoryID, "isActive": true });
                }

            };

            $scope.setCourseCatalogID = function (subcategory) {
                if (subcategory !== undefined) {
                    $scope.manageCourse.course.catalogId = subcategory.subcategoryID;
                }
            };

            $scope.createUniqueCategoryList = function () {

                //unique categories for the category dropdown
                $scope.uniqueCategories = [];
                $scope.taxonomyOptions.forEach(function (item) {
                    var catInd = _.filter($scope.uniqueCategories, { "categoryID": item.categoryID });
                    if (catInd.length < 1) {
                        var cat = { 'categoryName': item.categoryname, 'categoryID': item.categoryID, 'categoryNodeID': item.categoryNodeID };
                        $scope.uniqueCategories.push(cat);
                    }
                });

            };

            $scope.selectCategory = function () {
                $scope.subcategoryDisabled = false;
                //Get the selected catalog for the course and prepopulate drop downs
                var selectedCatalog = _.filter($scope.taxonomyOptions, { "subcategoryID": $scope.manageCourse.course.catalogId });

                if (selectedCatalog.length > 0) {
                    //category drop down
                    $scope.parentCategory = { 'categoryName': selectedCatalog[0].categoryname, 'categoryID': selectedCatalog[0].categoryID, 'categoryNodeID': selectedCatalog[0].categoryNodeID };

                    //subcategory drop down
                    $scope.subcategories = _.filter($scope.taxonomyOptions, { "categoryID": selectedCatalog[0].categoryID });

                    // set the selected subcateory
                    $scope.subcategory = selectedCatalog[0];
                }
            };

            $scope.openPreview = function (courseId) {
                var launchLink = CoursesService.getCoursePreviewUrl(courseId);
                launchLink.then(function (success) {
                    window.open(success, "_blank");
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.manageCourse.error.setError(errorCode, message);
                });
            };

            $scope.setDocumentContent = function (content) {
                if ($scope.manageCourse.documentType === "url") {
                    //handle as a URL 
                    $scope.manageCourse.documentUpload = null;
                }
                //Otherwise, it's a file 
                else {
                    $scope.manageCourse.urlUpload = null;

                    if (content[0].name.length > 200) {
                        $scope.manageCourse.error.setError(500, "File name must be under 200 characters");
                    }
                    $scope.manageCourse.documentUpload = content[0];
                    $scope.manageCourse.documentUploadName = content[0].name;
                }
            };

            $scope.uploadDocumentContent = function (fromWizard) {
                if (fromWizard) {
                    $scope.manageCourse.stepThree.isLoading = true;
                }
                if ($scope.manageCourse.documentType === 'url') {
                    _this.uploadDocumentUrl(fromWizard);
                } else {
                    _this.uploadDocumentFile(fromWizard);
                }
            };

            _this.uploadDocumentFile = function (fromWizard) {
                if ($scope.manageCourse.documentUpload === null) {
                    return;
                }

                var uploadDoc = DocumentService.uploadDocumentToStorage($scope.manageCourse.documentUpload, $scope.manageCourse.course.id);
                uploadDoc.then(function (success) {
                    console.log('successfully uploaded');
                    if (fromWizard) {
                        _this._showWizardSuccessScreen();
                    }
                    else {
                        _this.setDocumentPreviewStatus(true);
                    }
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.manageCourse.stepThree.error.setError(errorCode, message);
                    $scope.manageCourse.stepThree.isLoading = false;
                    _this.setDocumentPreviewStatus(false);
                });
            };

            _this.uploadDocumentUrl = function (fromWizard) {
                if ($scope.manageCourse.urlUpload === null) {
                    return;
                }
                var uploadUrl = DocumentService.uploadDocumentUrl($scope.manageCourse.urlUpload, $scope.manageCourse.course.id);
                uploadUrl.then(function (success) {
                    if (fromWizard) {
                        _this._showWizardSuccessScreen();
                    }
                    else {
                        _this.setDocumentPreviewStatus(true);
                    }
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.manageCourse.stepThree.error.setError(errorCode, message);
                    $scope.manageCourse.stepThree.isLoading = false;
                    _this.setDocumentPreviewStatus(false);
                });
            };

            $scope.setDocumentType = function (type) {
                $scope.manageCourse.documentType = type;
                if (type === 'url') {
                    $scope.manageCourse.showDocumentURLInput = true;
                    $scope.manageCourse.showDocumentFileInput = false;
                } else { //file 
                    $scope.manageCourse.showDocumentURLInput = false;
                    $scope.manageCourse.showDocumentFileInput = true;
                }
            };
        };
    }
]);

