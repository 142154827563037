var publicCatalogHosts = [
  "catalog-dev3.bai.org", // dev
  "catalog-dev-uscz.lms.ld.az.bai.org", //dev EKS
  "catalog-qa4.bai.org", // qa
  "catalog-uat2.bai.org", // uatAZ
  "catalog.bai.org", // prodAZ
  "catalog.web.local", // localhost
];

// the public catalog is now all React. (EA-10930)
var reactRoutes = [
  "/",
  "/react/:path*",
  "/student/:path*",
  "/student/messages/inbox",
  "/student/messages/requests",
  "/student/messages/enrollments",
  "/student/messages/tasks",
  "/help",
  "/help/student",
  "/help/instructor",
  "/help/traininginsights",
  "/help/ta",
  "/help/manager",
  "/help/studentdemovideo",
  "/help/instructordemovideo",
  "/help/traininginsightsdemovideo",
  "/help/managerdemovideo",
  "/student/home",
  "/student/profile",
  "/student/profile/edit-profile",
  "/student/profile/change-password",
  "/student/transcripts",
  "/catalog",
  "/catalog/:path*",
  "/courses",
  "/courses/certificate",
  "/courses/certificate/assignments",
  "/courses/certificate/assignments/:path*",
  "/documents-catalog",
  "/documents-catalog/:path*",
  "/admin/messages/enrollments",
  "/admin/messages/requests",
  "/admin/messages/tasks",
  "/admin",
  "/admin/:path*",
  "/admin-react",
  "/admin-react/:path*",
];
var unsupportedRoutes = [
  // These routes used to exist, but have been changed in React. TODO: Add redirects in React
  "/messages/inbox",
  "/messages/requests",
  "/messages/enrollments",
  "/messages/tasks",
  "/users/account",
];

window.customDocumentsEnabled = true;
window.fromWeb = publicCatalogHosts.reduce(function (isFromWeb, catalogHost) {
  if (isFromWeb || window.location.hostname === catalogHost) {
    return true;
  }
  return false;
}, false);
window.loggedin = false;
window.timeout = 15000;

/* App Module */
var app = angular.module("baiApp", [
  "ngRoute",
  "ngAnimate",
  "ngCookies",
  "ui.bootstrap",
  "AngularPrint",
  "ngMessages",
  "ngMaterial",
  "ngSanitize",
  "dibari.angular-ellipsis",
  "chart.js",
  "ngTagsInput",
  "ngTagsInput",
  "ngFileUpload",
  "mgo-angular-wizard",
  "treeControl",
  "infinite-scroll",
  "moment-picker",
  "timer",
  "baiCatalog",
]);

var catalog = angular.module("baiCatalog", []);

/**
 * Application configuration.
 */
app.config([
  "$compileProvider",
  "$mdThemingProvider",
  "$httpProvider",
  "$routeProvider",
  "$locationProvider",
  "$rootScopeProvider",
  "$mdDateLocaleProvider",
  "momentPickerProvider",
  function (
    $compileProvider,
    $mdThemingProvider,
    $httpProvider,
    $routeProvider,
    $locationProvider,
    $rootScopeProvider,
    $mdDateLocaleProvider,
    momentPickerProvider
  ) {
    var controllerName = "";
    $rootScopeProvider.digestTtl(15);

    $mdThemingProvider.theme("default").primaryPalette("light-blue");

    $mdDateLocaleProvider.parseDate = function (dateString) {
      var m = moment(dateString);
      //window.alert('Parsing "'+ dateString + '" isValid: ' + m.isValid());
      //console.log('parsing ' ); //+ dateString);
      return m.isValid() ? m.toDate() : new Date(NaN);
    };

    console.log("fromWeb:", window.fromWeb);

    reactRoutes.concat(unsupportedRoutes).forEach(function (route) {
      $routeProvider.when(route, {
        template: function (location) {
          console.log(
            '"' +
              location.path +
              '" is a React route. Delegating control to the React application.'
          );
          return '<div id="empty-angular-route" style={{ display: "none" }} />';
        },
      });
    });

    if (!window.fromWeb) {
      $routeProvider
        .when("/:controller/", {
          templateUrl: function (location) {
            controllerName = location.controller;
            return "app/templates/" + controllerName + "/index.html";
          },
          controller: function () {
            if (controllerName.toString() === "") {
              controllerName = "index";
            }

            return controllerName + "Controller";
          },
        })
        .when("/:controller/:name/", {
          templateUrl: function (location) {
            // Base URLs that are going to have the second parameter be dynamic, hence the location.name will never resolve to a template
            var unfindable = ["catalog", "search", "documents-catalog"];
            controllerName = location.controller;
            if (location.name === null) {
              location.name = "index";
            }

            if (jQuery.inArray(controllerName, unfindable) !== -1) {
              if (controllerName === "search") {
                return "app/templates/" + controllerName + "/mutable.html";
              } else {
                if (location.name === "search") {
                  return (
                    "app/templates/" + controllerName + "/catalogSearch.html"
                  );
                }
                return (
                  "app/templates/" + controllerName + "/catalogLibrary.html"
                );
              }
            } else {
              return (
                "app/templates/" +
                controllerName +
                "/" +
                location.name +
                ".html"
              );
            }
          },
          controller: function () {
            if (controllerName.toString() === "") {
              controllerName = "index";
            }

            return controllerName + "Controller";
          },
        })
        .when("/:controller/:name/:param*", {
          templateUrl: function (location) {
            // Base URLs that are going to have the second parameter be dynamic, hence the location.name will never resolve to a template
            var unfindable = ["catalog", "documents", "documents-catalog"];

            controllerName = location.controller;

            if (location.name === null) {
              location.name = "index";
            }

            if (jQuery.inArray(controllerName, unfindable) !== -1) {
              if (controllerName === "search") {
                return "app/templates/" + controllerName + "/mutable.html";
              } else {
                if (location.name === "search") {
                  return (
                    "app/templates/" + controllerName + "/catalogSearch.html"
                  );
                } else if (location.name === "product") {
                  return "app/templates/" + controllerName + "/product.html";
                }
                return (
                  "app/templates/" + controllerName + "/catalogLibrary.html"
                );
              }
            } else {
              return (
                "app/templates/" +
                controllerName +
                "/" +
                location.name +
                ".html"
              );
            }
          },
          controller: function () {
            if (controllerName.toString() === "") {
              controllerName = "index";
            }

            return controllerName + "Controller";
          },
        });
    }

    $locationProvider.html5Mode(true);

    $httpProvider.interceptors.push(function ($q) {
      return {
        // handel both the old-way and new-way of in-bound AJAX payloads
        response: function (response) {
          /**
           * response can be null
           */
          if (typeof response.data.response !== "undefined") {
            response.data = response.data.response;
          }

          return response;
        },

        responseError: function (rejection) {
          var data = rejection.data;

          if (
            typeof data !== "undefined" &&
            data !== null &&
            typeof data.message !== "undefined" &&
            data.message !== null &&
            typeof data.ErrorMessage === "undefined"
          ) {
            data.ErrorMessage = data.message;
          }

          if (
            typeof data !== "undefined" &&
            data !== null &&
            typeof data.Message !== "undefined" &&
            data.Message !== null &&
            typeof data.ErrorMessage === "undefined"
          ) {
            data.ErrorMessage = data.Message;
          }

          // First check is for when there is a timeout
          if (data === null || typeof data.ErrorMessage === "undefined") {
            // Resets the data to an object in case what we got from the API was not a valid object for us.
            data = {};

            data.ErrorCode = 0;
            data.ErrorMessage =
              "An unknown error has occurred. Please contact your organization's training administrator.";
            if (
              typeof window.Cookies.get("userRole") !== "undefined" &&
              window.Cookies.get("userRole").indexOf("TA") > 0
            ) {
              data.ErrorMessage =
                "Time out error. Please try again. If the issue persists, please contact BAI Customer Support Services";
            }
            data.StatusCode = 500;
          }

          return $q.reject(data);
        },
      };
    });

    //$compileProvider.preAssignBindingsEnabled(true);

    momentPickerProvider.options({
      format: "L LT",
      startView: "month",
      today: true,
      locale: "en",
      minView: "decade",
      maxView: "month",
      autoclose: true,
      keyboard: false,
      /* Extra: Views properties */
      leftArrow: "&larr;",
      rightArrow: "&rarr;",
      yearsFormat: "YYYY",
      monthsFormat: "MMM",
      daysFormat: "D",
      hoursFormat: "HH:[00]",
      minutesFormat: moment
        .localeData()
        .longDateFormat("LT")
        .replace(/[aA]/, ""),
      secondsFormat: "ss",
      minutesStep: 15,
      secondsStep: 1,
    });
  },
]);

app.run([
  "$rootScope",
  "$location",
  "$http",
  "$window",
  "$route",
  "CssService",
  "UsersService",
  "SettingsService",
  "urlParamsUtil",
  "popupcheckUtil",
  "$templateRequest",
  "$templateCache",
  "$interval",
  "queuePollingInterval",
  function (
    $rootScope,
    $location,
    $http,
    $window,
    $route,
    CssService,
    UsersService,
    SettingsService,
    urlParamsUtil,
    popupcheckUtil,
    $templateRequest,
    $templateCache,
    $interval,
    queuePollingInterval
  ) {
    //allows changing path without reloading controller
    var original = $location.path;
    $location.path = function (path, reload) {
      if (reload === false) {
        var lastRoute = $route.current;
        var un = $rootScope.$on("$locationChangeSuccess", function () {
          $route.current = lastRoute;
          un();
        });
      }

      if (
        $location.$$path === "/reports/" &&
        $route &&
        $route.current &&
        $route.current.params &&
        $route.current.params.controller === "users" &&
        $route.current.params.name === "home"
      ) {
        /** EA-14021 This is needed for the following scenario:
         * 1. As a user with multiple roles (admin, manager, instructor)
         * 2. Navigate among the various /users/home pages (Admininstration, Manager Dashboard, Instructors)
         * 3. Go to Reports
         * 4. Navigate among the various /users/home pages (Admininstration, Manager Dashboard, Instructors)
         * Expected Result: the pages navigate normally
         * Actual Result without the code in this block: The second page the user navigates to brings the user
         * back to the /reports/ page.
         */
        $location.$$path = "/users/home/";
        $location.$$url = "/users/home/";
        $location.$$absUrl = $location.$$absUrl.replace(
          "/reports/",
          "/users/home/"
        );
      }

      return original.apply($location, [path]);
    };

    /**
     * This cannot continually run in the $locationChangeStart, based on SLY-2328
     * This has to run once. If it runs multiple times, the moment dates will have a value of
     * Invalid Date, as
     */
    UsersService.setFromCookies();
    

    /* I would put this in an ng-include but I can't
     * due to the way wix's angular tree imports templates. see
     * https://github.com/wix/angular-tree-control/pull/251
     * and
     * https://github.com/wix/angular-tree-control/issues/167
     *
     * SLY-3952, SLY-3953 implemented a limit-to attribute on the treecontrol directive to limit the number of nodes / sub-nodes that are rendered. setting
     * is global to the tree.
     */
    var treeTemplate = "";
    treeTemplate += "<ul {{options.ulClass}} render-on-scroll>";
    treeTemplate +=
      '    <li ng-repeat="node in node.{{options.nodeChildren}} | filter:filterExpression:filterComparator {{options.orderBy}} {{options.limitTo}}"';
    treeTemplate += '     ng-class="headClass(node)"';
    treeTemplate += "  {{options.liClass}}";
    treeTemplate += "  set-node-to-data>";

    treeTemplate += '       <div class="treeRow" ng-class="[selectedClass()]">';
    treeTemplate += '         <div class="iconAndName">';
    /* using node.parentId to determine if something is a root node
     * is hacky and should be replaced with something more robust.
     * Perhaps defining it in the treedata itself?
     */
    treeTemplate +=
      '            <img ng-if="node.pencilIcon" width="16" height="16" ng-click="node.nodeClick(node); $event.stopPropagation()" ng-src="app/images/premiumAdmin/pencil_tool.svg" />';
    treeTemplate +=
      '            <img ng-if="node.lockIcon" width="16" height="16" src="app/images/premiumAdmin/lock.svg" />';
    treeTemplate += "            <!--";
    treeTemplate += "            Displays icons next to leaves.";
    treeTemplate += "            -->";
    //treeTemplate += '            <i class="tree-leaf-head {{options.iLeafClass}}"></i>';
    treeTemplate +=
      '            <div class="tree-label {{options.labelClass}}" ng-click="selectNodeLabel(node)" tree-transclude></div>';
    treeTemplate += "         </div>";
    treeTemplate +=
      '            <i class="tree-branch-head openClose" ng-class="iBranchClass()" ng-click="selectNodeHead(node)">';
    treeTemplate += '                <span ng-if="!nodeExpanded()">';
    treeTemplate += "                +";
    treeTemplate += "                </span>";
    treeTemplate += '                <span ng-if="nodeExpanded()">';
    treeTemplate += "                -";
    treeTemplate += "                </span>";
    treeTemplate += "            </i>";
    treeTemplate += "        </div>";
    treeTemplate += '        <treeitem ng-if="nodeExpanded()"></treeitem>';
    treeTemplate += "    </li>";
    treeTemplate += "</ul>";

    $templateCache.put("tree.html", treeTemplate);

    /**
     * Application globals
     */
    // Bool to see if custom css should be checked for. If XHR request was made, checkCss will be false.
    $rootScope.checkCss = true;
    // Bool to see if cache should be reset for a singular XHR request
    $rootScope.resetCache = true;
    // Bool to ensure the video is shown ONLY once a session
    $rootScope.videoShownThisSession = false;
    // sysSettings Objects which holds the data from SettingsService for feature enabling.
    $rootScope.sysSettings = {};

    $rootScope.css = "";
    $rootScope.cssDoneLoading = false;
    $rootScope.fromWeb = window.fromWeb;
    if ($rootScope.fromWeb === true) {
      $rootScope.metaTags = {};
      $rootScope.metaTags.title = "";
      $rootScope.metaTags.description = "";
      $rootScope.metaTags.keywords = "";
    }

    // Logic to run on every page turn.
    $rootScope.$on("$locationChangeStart", function (event, next, current) {
      // the public catalog is all handled by react now
      if ($rootScope.fromWeb) {
        return;
      }

      var css = null;
      var search = "";
      var sysSettings = null;

      if (UsersService.isLoggedIn()) {
        sysSettings = SettingsService.getSettings();
        sysSettings.then(
          function (success) {
            $rootScope.sysSettings = success;
          },
          function () {}
        );

        if (
          UsersService.getPortalCode() !== null &&
          $rootScope.checkCss === true
        ) {
          $location.search("portal", null);
        }

        if (
          typeof $location.search().portal !== "undefined" &&
          $rootScope.checkCss === true
        ) {
          if (jQuery("#portalStyles").length > 0) {
            CssService.purgeResults();

            jQuery("#portalStyles").remove();
          }

          search = $location.search().portal;
          css = CssService.fetchByPortalId(search);
          css.then(
            function (success) {
              $rootScope.cssDoneLoading = true;

              $rootScope.css = success;

              jQuery("<style id='portalStyles'></style>")
                .appendTo("body")
                .html(success);
            },
            function () {
              $location.search("portal", null);
            }
          );

          $rootScope.checkCss = false;
        } else if ($rootScope.checkCss === true) {
          if (jQuery("#portalStyles").length > 0) {
            CssService.purgeResults();

            jQuery("#portalStyles").remove();
          }

          search = UsersService.getId();
          css = CssService.fetchByUserId(search);

          css.then(
            function (success) {
              $rootScope.cssDoneLoading = true;

              $rootScope.css = success;

              jQuery("<style id='portalStyles'></style>")
                .appendTo("body")
                .html(success);
            },
            function () {
              $location.search("portal", null);
            }
          );

          $rootScope.checkCss = false;
        }

        if ($location.path() === "/") {
          UsersService.navigateToHome();
        }

        popupcheckUtil.openPopUp();
      } else {
        if ($location.path() !== "/") {
          var logout = UsersService.clearSession();
          logout.then(
            function (success) {
              $location.url("/");
              return;
            },
            function (failure) {}
          );

          if ($rootScope.fromWeb === false) {
            $location.path("/");
          }
        }
      }

      // If the location changes cancel intervals
      if (queuePollingInterval.peopleGroupPollingInterval !== null) {
        $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
      }
    });

    $rootScope.$on("$routeChangeSuccess", function (e, current) {
      $rootScope.query = "";
      if ($rootScope.fromWeb === true) {
        $rootScope.metaTags.title = "";
        $rootScope.metaTags.description = "";
        $rootScope.metaTags.keywords = "";
      }
      if (typeof $location.search().portal !== "undefined") {
        $rootScope.query = "?portal=" + $location.search().portal;
      }
    });
    /* add css class to non-production environments to distinguish from production */
    (function () {
      var loc = window.location.hostname.toLowerCase();
      var e = document.getElementsByTagName("body")[0];
      if (e && e.classList) {
        if (loc === "lmgr.bai.org" || loc === "catalog.bai.org") {
          /* don't add any classes in production */
        } else if (
          loc === "lmgr-dev.bai.org" ||
          loc === "catalog-dev.bai.org"
        ) {
          e.classList.add("lmgr-dev");
        } else if (loc === "lmgr-qa.bai.org" || loc === "catalog-qa.bai.org") {
          e.classList.add("lmgr-qa");
        } else if (
          loc === "lmgr-uat.bai.org" ||
          loc === "catalog-uat.bai.org"
        ) {
          e.classList.add("lmgr-uat");
        } else {
          e.classList.add("not-production");
        }
      }
    })();
    /* Add long-lived device cookie for debug tracing and internal analytics in log files.
	We can't rely on presence of Google cookies due to ad-blockers, and do not want
	to log session IDs for security reasons. Web server logging (nginx) should be
	changed to log this cookie instead of session cookies */
    (function () {
      var cookieName = "udg";
      if (document.cookie.indexOf(cookieName + "=") === -1) {
        document.cookie =
          cookieName +
          "=" +
          sqlguid() +
          ";Secure;Path=/;Domain=bai.org;Expires=" +
          new Date(
            new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
          ).toUTCString();
      }
    })();

    /* set timeout to 15 seconds on dev and qa. 60 seconds on prod and uat */
    (function () {
      var loc = window.location.hostname.toLowerCase();
      if (loc === "lmgr.bai.org" || loc === "lmgr-uat.bai.org") {
        window.timeout = 60000;
      }
    })();
  },
]);
